import { render, staticRenderFns } from "./LogoMedicom.vue?vue&type=template&id=25fec107&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/michalkonvicka/foreground/4g/themes/foreground-theme-2020/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25fec107')) {
      api.createRecord('25fec107', component.options)
    } else {
      api.reload('25fec107', component.options)
    }
    module.hot.accept("./LogoMedicom.vue?vue&type=template&id=25fec107&", function () {
      api.rerender('25fec107', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/svg/LogoMedicom.vue"
export default component.exports