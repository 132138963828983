var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("footer", [
      _c("div", { attrs: { id: "detail-sluzby-side-shadow" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "cta-side" } }, [
        _c(
          "div",
          { attrs: { id: "cta-side-wrapper" } },
          [
            _c("div", { attrs: { id: "cta-side-controls-container" } }, [
              _c("div", { staticClass: "detail-sluzby-controls" }, [
                _c(
                  "div",
                  {
                    staticClass: "detail-sluzby-close-btn",
                    staticStyle: {
                      position: "relative",
                      width: "20px",
                      height: "20px"
                    },
                    on: {
                      click: function($event) {
                        return _vm.skrytCTA()
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "ox-toggler__bar",
                      staticStyle: {
                        position: "absolute",
                        transform: "rotate(-45deg)",
                        top: "50%",
                        left: "0"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "ox-toggler__bar",
                      staticStyle: {
                        position: "absolute",
                        transform: "rotate(45deg)",
                        top: "50%",
                        left: "0"
                      }
                    })
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("CTAForm")
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }