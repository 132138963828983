var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "220",
        height: "120",
        viewBox: "0 0 220 120"
      }
    },
    [
      _c("g", { attrs: { id: "loreal", transform: "translate(44 32.999)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_26",
            "data-name": "Rectangle 26",
            width: "220",
            height: "120",
            transform: "translate(-44 -32.999)",
            fill: "none"
          }
        }),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: {
              id: "L_Oréal_logo",
              "data-name": "L&apos;Oréal_logo",
              transform: "translate(-12.945 13.116)"
            }
          },
          [
            _c("path", {
              attrs: {
                id: "polyline3",
                d:
                  "M435.229,42.194h20.246V39.721H438.7V31.808h12.6v-2.5H438.7V22.015h16.775V19.551H435.229V42.194",
                transform: "translate(-349.873 -15.717)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "polyline5",
                d: "M477.642.887,474.9,3.966,483.506.887h-5.864",
                transform: "translate(-381.765 -0.713)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "polyline7",
                d: "M698.8,39.721V19.551h-3.164V42.194H716.1V39.721H698.8",
                transform: "translate(-559.211 -15.717)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "path9",
                d:
                  "M558.165,19.551,546.652,42.194h3.892l2.945-5.861H567.3l2.971,5.861h3.852L562.6,19.551h-4.432M554.947,33.5l5.425-10.815L565.861,33.5Z",
                transform: "translate(-439.444 -15.717)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "path11",
                d:
                  "M311.419,32.726c5.187-1.384,5.691-5.244,5.665-6.624-.31-4.045-3.035-6.551-7.932-6.551H294.88V42.194h3.253V32.7h9.291l6.835,9.5h4.1s-4.919-6.393-6.942-9.468m-2.711-2.706H298.133V22.231h10.942c2.519,0,3.936,1.176,4.447,2.67a4.221,4.221,0,0,1-.438,3.284C312.171,29.7,310.433,30.02,308.707,30.02Z",
                transform: "translate(-237.049 -15.717)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "path13",
                d:
                  "M131.886,0c-9.184,0-15.443,6.475-15.443,14.312,0,8.251,6.914,14.075,15.443,14.075s15.435-5.741,15.435-14.075C147.321,6.475,140.984,0,131.886,0m-.11,25.577A11.5,11.5,0,0,1,120.187,14.32a11.713,11.713,0,0,1,23.421,0C143.609,20.471,138.127,25.577,131.776,25.577Z",
                transform: "translate(-93.607)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "polyline15",
                d: "M74.663,27.561h2.348l4.261-8.01H77.843l-3.18,8.01",
                transform: "translate(-60.02 -15.717)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "polyline17",
                d: "M3.166,39.721V19.551H0V42.194H20.467V39.721H3.166",
                transform: "translate(0 -15.717)"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }