<template>
    <div class="heading-w-line">
        <h2>
            <slot/>
        </h2>
        <div class="line" :class="{ white: this.theme == 'dark' }"></div>
    </div>
</template>

<script>
    export default {
        props: {
            theme: {
                type: String,
                default: "light"
            },
        },
    }
</script>

<style lang="scss" scoped>
.heading-w-line{
    margin-bottom: 60px;
}
.heading-w-line h2{
    margin-bottom: 0px;
}
.line{
    width: 100%;
    height: 20px;
    border-bottom: 1px black solid;
    border-right: 1px black solid;
    &.white{
        border-bottom: 1px white solid;
        border-right: 1px white solid;
    }
}
</style>