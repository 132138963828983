<template>
    <div>
        <div class="sluzby-bg">
            <div :class="{ 'black-background': isScrolled }" class="screen-background"></div>
            <div class="sluzby-intro" style="position: relative;">
                <div class="grainy-bg-container" style="">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" style="width: 100%; height: 100%;">
                        <defs><linearGradient gradientTransform="rotate(-208, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="gggrain-gradient2"><stop stop-color="hsla(36, 18%, 65%, 1.00)" stop-opacity="1" offset="-0%"></stop><stop stop-color="rgba(255,255,255,0)" stop-opacity="0" offset="100%"></stop></linearGradient><linearGradient gradientTransform="rotate(208, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="gggrain-gradient3"><stop stop-color="hsl(37, 87%, 55%)" stop-opacity="1"></stop><stop stop-color="rgba(255,255,255,0)" stop-opacity="0" offset="100%"></stop></linearGradient><filter id="gggrain-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="2" seed="145" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence"></feTurbulence>
                            <feColorMatrix type="saturate" values="0" x="0%" y="0%" width="100%" height="100%" in="turbulence" result="colormatrix"></feColorMatrix>
                            <feComponentTransfer x="0%" y="0%" width="100%" height="100%" in="colormatrix" result="componentTransfer">
                                <feFuncR type="linear" slope="3"></feFuncR>
                                <feFuncG type="linear" slope="3"></feFuncG>
                                <feFuncB type="linear" slope="3"></feFuncB>
                            </feComponentTransfer>
                            <feColorMatrix x="0%" y="0%" width="100%" height="100%" in="componentTransfer" result="colormatrix2" type="matrix" values="1 0 0 0 0
                                    0 1 0 0 0
                                    0 0 1 0 0
                                    0 0 0 22 -14"></feColorMatrix>
                            </filter></defs><g><rect width="100%" height="100%" fill="hsl(220, 1%, 95%)"></rect><rect width="100%" height="100%" fill="url(#gggrain-gradient3)"></rect><rect width="100%" height="100%" fill="url(#gggrain-gradient2)"></rect><rect width="100%" height="100%" fill="transparent" filter="url(#gggrain-filter)" opacity="0.7" style="mix-blend-mode: soft-light"></rect></g>
                    </svg> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" style="width: 100%; height: 100%;">
                        <defs><linearGradient gradientTransform="rotate(-212, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="gggrain-gradient2"><stop stop-color="hsla(36, 0%, 74%, 1.00)" stop-opacity="1" offset="-0%"></stop><stop stop-color="rgba(255,255,255,0)" stop-opacity="0" offset="100%"></stop></linearGradient><linearGradient gradientTransform="rotate(212, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="gggrain-gradient3"><stop stop-color="hsl(0, 0%, 87%)" stop-opacity="1"></stop><stop stop-color="rgba(255,255,255,0)" stop-opacity="0" offset="100%"></stop></linearGradient><filter id="gggrain-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feTurbulence type="fractalNoise" baseFrequency="0.72" numOctaves="2" seed="79" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence"></feTurbulence>
                        <feColorMatrix type="saturate" values="0" x="0%" y="0%" width="100%" height="100%" in="turbulence" result="colormatrix"></feColorMatrix>
                        <feComponentTransfer x="0%" y="0%" width="100%" height="100%" in="colormatrix" result="componentTransfer">
                            <feFuncR type="linear" slope="3"></feFuncR>
                            <feFuncG type="linear" slope="3"></feFuncG>
                            <feFuncB type="linear" slope="3"></feFuncB>
                        </feComponentTransfer>
                        <feColorMatrix x="0%" y="0%" width="100%" height="100%" in="componentTransfer" result="colormatrix2" type="matrix" values="1 0 0 0 0
                                0 1 0 0 0
                                0 0 1 0 0
                                0 0 0 18 -10"></feColorMatrix>
                        </filter></defs><g><rect width="100%" height="100%" fill="hsl(0, 0%, 98%)"></rect><rect width="100%" height="100%" fill="url(#gggrain-gradient3)"></rect><rect width="100%" height="100%" fill="url(#gggrain-gradient2)"></rect><rect width="100%" height="100%" fill="transparent" filter="url(#gggrain-filter)" opacity="0.63" style="mix-blend-mode: soft-light"></rect></g>
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" style="width: 100%; height: 100%;">
                        <defs><linearGradient gradientTransform="rotate(-214, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="gggrain-gradient2"><stop stop-color="hsla(36, 0%, 74%, 1.00)" stop-opacity="1" offset="-0%"></stop><stop stop-color="rgba(255,255,255,0)" stop-opacity="0" offset="100%"></stop></linearGradient><linearGradient gradientTransform="rotate(214, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="gggrain-gradient3"><stop stop-color="hsl(0, 0%, 87%)" stop-opacity="1"></stop><stop stop-color="rgba(255,255,255,0)" stop-opacity="0" offset="100%"></stop></linearGradient><filter id="gggrain-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feTurbulence type="fractalNoise" baseFrequency="0.53" numOctaves="2" seed="2" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence"></feTurbulence>
                        <feColorMatrix type="saturate" values="0" x="0%" y="0%" width="100%" height="100%" in="turbulence" result="colormatrix"></feColorMatrix>
                        <feComponentTransfer x="0%" y="0%" width="100%" height="100%" in="colormatrix" result="componentTransfer">
                            <feFuncR type="linear" slope="3"></feFuncR>
                            <feFuncG type="linear" slope="3"></feFuncG>
                            <feFuncB type="linear" slope="3"></feFuncB>
                        </feComponentTransfer>
                        <feColorMatrix x="0%" y="0%" width="100%" height="100%" in="componentTransfer" result="colormatrix2" type="matrix" values="1 0 0 0 0
                                0 1 0 0 0
                                0 0 1 0 0
                                0 0 0 17 -9"></feColorMatrix>
                        </filter></defs><g><rect width="100%" height="100%" fill="hsl(220, 1%, 95%)"></rect><rect width="100%" height="100%" fill="url(#gggrain-gradient3)"></rect><rect width="100%" height="100%" fill="url(#gggrain-gradient2)"></rect><rect width="100%" height="100%" fill="transparent" filter="url(#gggrain-filter)" opacity="0.57" style="mix-blend-mode: soft-light"></rect></g>
                    </svg>
                </div>
                <div class="intro-heading-container" style="position: relative; z-index: 2;">
                    <!-- <div class="intro-bg-linear-gradient"></div> -->
                    <div class="container">
                        <h1>
                            <span class="h1-line">
                                <span class="hp_h1_efekt"></span>
                            </span>
                        </h1>
                        <p class="text-rotujici">{{ subtitleDisplayText  }}</p>
                        <div class="intro-subtitle-container">
                            <div class="intro-line-1">
                                <p class="intro-p-top">Jsme reklamní agentura zajišťující komplexní služby v oblasti reklamy a marketingu. </p>
                                <div class="line"></div>
                                <a href="#sluzby">
                                    <button>Naše služby</button>
                                </a>
                            </div>
                            <div>
                                <p class="intro-p-top">
                                    Pomáháme klientům zorientovat se v rychle vyvíjejícím světě marketingu a společně <br>
                                    nacházíme řešení, která dávají smysl, fungují a přinášejí výsledky.
                                </p>
                            </div>
                        </div>
                        <!-- <div class="intro-btn-container">
                            <a class="round-btn">
                                <div class="inner-bg"></div>
                                <div class="inner">
                                    <span>Let's </span><span>talk</span>
                                </div>
                            </a>
                        </div> -->
                    </div>
                    <!-- <img src="theme/images/sluzby/print/print_intro.jpg" alt=""> -->
                </div>
                <div class="container" style="position: relative; z-index: 2;">
                    <div class="loga-container" :class="{ 'dark-theme': isScrolled }">
                        <div class="logo-wrapper">
                            <logo-loreal></logo-loreal>
                        </div>
                        <div class="logo-wrapper">
                            <logo-medicom></logo-medicom>
                        </div>
                        <div class="logo-wrapper">
                            <LogoAtex></LogoAtex>
                        </div>
                        <div class="logo-wrapper">
                            <logo-topestates></logo-topestates>
                        </div>
                        <div class="logo-wrapper">
                            <LogoPZK></LogoPZK>
                        </div>
                        <div class="logo-wrapper">
                            <LogoBrand></LogoBrand>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" style="position: relative; z-index: 2;">
            </div>
            <div id="sluzby" class="sluzby-wrapper" style="position: relative; z-index: 2;">
                <div class="container">
                    <HeadingWithLine :theme="'dark'">
                        <span class="sluzby_heading">Služby</span>
                    </HeadingWithLine>
                    <ul class="sluzby-list">
                        <li 
                            v-for="sluzba in sluzby" 
                            :key="sluzba.id"
                            @click="zobrazitDetailSluzby(sluzba)"
                            :class="{'disabled': sluzba.disabled,}"
                            class="scroll-efekt"
                        >
                            <div class="sluzby-list-item">
                                <span class="sluzby-list-title">{{ sluzba.name }}</span>
                                <span 
                                    v-if="sluzba.tags"
                                    class="sluzby-list-tags"
                                >
                                    <span
                                        v-for="tag in sluzba.tags"
                                        :key="tag"
                                        class="sluzby-list-tag"
                                    >
                                    {{ tag }} 
                                    </span>
                                </span>
                                <span class="tag-pripravujeme-container" v-if="sluzba.disabled">
                                    <span  class="tag-pripravujeme">Připravujeme prezentaci</span>
                                </span>
                                <span class="arrow-container" v-else>
                                    <div class="arrow_svg_tr_container">
                                        <svg class="arrow1_svg_tr" xmlns="http://www.w3.org/2000/svg" height="70px" viewBox="0 -960 960 960" width="70px" fill="#5f6368"><path d="M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"/></svg>
                                        <svg class="arrow2_svg_tr" xmlns="http://www.w3.org/2000/svg" height="70px" viewBox="0 -960 960 960" width="70px" fill="#5f6368"><path d="M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"/></svg>
                                    </div>
                                    <!-- <svg class="arrow_svg_transition" xmlns="http://www.w3.org/2000/svg" height="70px" viewBox="0 -960 960 960" width="70px" fill="#5f6368"><path d="M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"/></svg> -->
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div>
                <p>{{ JSON.parse(sluzby_list) }}</p>
                <p>{{ sluzby }}</p>
            </div> -->
            <!-- <ScrollCarousel>
                <template #heading>
                    <div class="container">
                        <HeadingWithLine>
                            Jaké máme novinky
                        </HeadingWithLine>
                    </div>
                </template>
                <div class="carusel-item" v-for="(item, index) in caruselItems" :key="index">
                    <img :src="item.src" alt="">
                    <div class="carusel-item-text-wrapper">
                        <h3>Krásnější díky plastice</h3>
                        <p class="typ">Web</p>
                    </div>
                </div>
            </ScrollCarousel> -->
        </div>
        <div id="detail-sluzby-side-shadow"></div>
        <div id="detail-sluzby-side" class="detail-sluzby">
            <div id="detail-sluzby-side-wrapper">
                <div id="detail-sluzby-controls-container">
                    <div id="detail-sluzby-controls" class="detail-sluzby-controls">
                        <div 
                            style="position:relative;width:20px;height: 20px;"
                            @click="skrytDetalSluzby()"
                            class="detail-sluzby-close-btn"
                        >
                            <span class="ox-toggler__bar" style="position:absolute; transform: rotate(-45deg);top:50%;left:0;"></span>
                            <span class="ox-toggler__bar" style="position:absolute; transform: rotate(45deg);top:50%;left:0;"></span>
                        </div>
                    </div>
                </div>
                <sluzba-detail :sluzba="this.detailSluzby" :sluzbaSide="true"></sluzba-detail>
            </div>
        </div>
        <div style="position: relative; z-index: 3;">
            <CTA :theme="'dark'"></CTA>
            <!-- <Footer></Footer> -->
        </div>
    </div>
</template>

<script>
import SluzbaDetail from './SluzbaDetail.vue';
import Footer from './Footer.vue';
import CTA from './CTA.vue';
import { animate, scroll } from "motion";
import LogoAtex from './svg/LogoAtex.vue'
import LogoBrand from './svg/LogoBrand.vue'
import LogoLoreal from './svg/LogoLoreal.vue'
import LogoMedicom from './svg/LogoMedicom.vue'
import LogoPZK from './svg/LogoPZK.vue'
import LogoTopestates from './svg/LogoTopestates.vue'
import HeadingWithLine from './ui/HeadingWithLine.vue';
import ScrollCarousel from './ui/ScrollCarousel.vue';

    export default {
        components: { 
            SluzbaDetail, 
            Footer, LogoAtex, LogoBrand, LogoLoreal, LogoMedicom, LogoPZK, LogoTopestates, 
            HeadingWithLine, ScrollCarousel,
            CTA
        },
        data() {
            return {
                headingText : "Přes 10 let",
                headingDisplayText : "",
                subtitles: ["Tvoříme", "Pracujeme", "Analyzujeme", "Navrhujeme", "Vymýšlíme"],
                subtitleDisplayText : "",
                scrollY: 0,
                caruselItems: [
                    { src: '/theme/images/klienti/medicom_karusel_1.jpg' },
                    { src: '/theme/images/klienti/medicom_karusel_2.jpg' },
                    { src: '/theme/images/klienti/medicom_karusel_3.jpg' },
                    { src: '/theme/images/klienti/medicom_karusel_2.jpg' },
                    { src: '/theme/images/klienti/medicom_karusel_3.jpg' },
                    { src: '/theme/images/klienti/medicom_karusel_2.jpg' },
                ],
                sluzby: [
                    {   
                        id: 1,
                        name: "Aplikace a Appky",
                        url: "aplikace-a-Appky",
                        disabled: true
                    },
                    {   
                        id: 2,
                        name: "Automatizace",
                        url: "automatizace",
                        disabled: false
                    },
                    {   
                        id: 3,
                        name: "Brand a Identita",
                        url: "brand-a-identita",
                        disabled: true
                    },
                    {   
                        id: 4,
                        name: "E-shopy",
                        url: "e-shopy",
                        disabled: false
                    },
                    {   
                        id: 5,
                        name: "Integrace",
                        url: "integrace",
                        disabled: true
                    },
                    {   
                        id: 6,
                        name: "Logo a Logotyp",
                        url: "logo-a-logotyp",
                        disabled: true
                    },
                    {   
                        id: 7,
                        name: "Microsites",
                        url: "microsites",
                        disabled: true
                    },
                    {   
                        id: 8,
                        name: "Obalový design",
                        url: "obalovy-design",
                        disabled: true
                    },
                    {   
                        id: 9,
                        name: "Obsahová strategie",
                        url: "obsahova-strategie",
                        disabled: true,
                        tags:[
                            'Novinka',
                            'Valuepack'
                        ]
                    },
                    {   
                        id: 10,
                        name: "Print",
                        url: "print",
                        disabled: false
                    },
                    {   
                        id: 11,
                        name: "SEO",
                        url: "seo",
                        disabled: true
                    },
                    {   
                        id: 12,
                        name: "Weby",
                        url: "weby",
                        disabled: true
                    },
                ],
                detailSluzby: null,
            }
        },
        mounted() {
            this.displayText(); 
            setTimeout(() => {
                this.rotateTexts();
            }, 500);
            // let sideContainer = document.getElementById('detail-sluzby-side')
            // let closeBtn = document.getElementById('detail-sluzby-controls')
            // sideContainer.addEventListener('scroll', () => {
            //     // Aktualizace pozice tlačítka při scrollování
            //     closeBtn.style.top = sideContainer.scrollTop + 'px';
            // })
            window.addEventListener("scroll", this.handleScroll);
        },
        computed: {
            isScrolled() {
                console.log(this.scrollY);
                
                return this.scrollY > 400;
            },
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleScroll() {
                this.scrollY = window.scrollY;
            },
            displayText() {
                let index = 0;
                let intervalSubtitle = setInterval(() => {
                    if (index < this.headingText.length) {
                        this.headingDisplayText += this.headingText[index];
                        index++;
                    } else {
                        clearInterval(intervalSubtitle);
                    }
                }, 50);
            },
            rotateTexts(){
                let index = 0;
                let indexSubtitle = 0;
                const intervalFunction = () => {
                    this.subtitleDisplayText = ""
                    let intervalId = setInterval(() => {
                        if (index < this.subtitles[indexSubtitle].length) {
                            this.subtitleDisplayText += this.subtitles[indexSubtitle][index];
                            index++;
                        } else {
                            clearInterval(intervalId);
                            index = 0;
                            indexSubtitle++;
                            if (indexSubtitle >= this.subtitles.length) {
                                indexSubtitle = 0;  
                            }
                            setTimeout(() => {
                                intervalFunction();
                            }, 2000); 
                        }
                    }, 50);
                };
                intervalFunction();  
            },
            zobrazitDetailSluzby(sluzba) {
                this.detailSluzby = sluzba;
                if(!sluzba.disabled){
                    window.history.replaceState(null, null, `/${sluzba.url}`)
                    document.getElementById("detail-sluzby-side").classList.add("active")
                    document.getElementById("detail-sluzby-side-shadow").classList.add("active")
                    document.body.style.overflow = "hidden";
                }
                if(sluzba.id == 4){
                    document.querySelectorAll('#detail-sluzby-side .detail-sluzby-close-btn .ox-toggler__bar').forEach((line) => {
                        line.style.backgroundColor = "white"
                    })
                }else{
                    document.querySelectorAll('#detail-sluzby-side .detail-sluzby-close-btn .ox-toggler__bar').forEach((line) => {
                        line.style.backgroundColor = "white"
                    })
                }
                setTimeout(() => {
                    let sideContainer = document.getElementById('detail-sluzby-side-wrapper')
                    let sideControlContainer = document.getElementById('detail-sluzby-controls-container')
                    sideControlContainer.style.height = sideContainer.offsetHeight + "px"
                    console.log(sideContainer.offsetHeight);
                }, 100);
            },
            skrytDetalSluzby(){
                window.history.pushState(null, null, `/sluzby`)
                document.getElementById("detail-sluzby-side").classList.remove("active")
                document.getElementById("detail-sluzby-side-shadow").classList.remove("active")
                document.body.style.overflow = "scroll";
            }
        },
    }
</script>

<style lang="scss" scoped>
a{
    text-decoration: none;
}
.intro-subtitle-container{
    margin-top: 30px;
}
.intro-line-1{
    display: flex;
    gap: 20px;
    align-items: center;
    .line{
        flex: 1;
        height: 1px;
        width: 100%;
        background-color: RGB(130, 131, 132);
        transform: translateY(12px);
    }
    button{
        position: relative;
        border:none;
        // background-color: black;
        color: white;
        border-radius: 60px;
        padding: 0px 42px;
        font-size: 24px;
        display: flex;
        gap: 12px;
        transition: all .3s;
        z-index: 1;
        &::before{
            content: "";
            width: 100%;
            height: 200%;

            position: absolute;
            top: 0px;
            left: 0px;
            background-color: black;
            border-radius: 60px;
            transform: translateY(-25%);
            z-index: -1;
        }
    }
}
.intro-heading-container{
    flex: 1;
    display: flex;
    align-items: center;
}
.loga-container{
    padding-top: 0px;
    padding-bottom: 60px;
    // padding-bottom: 0px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .logo-wrapper{
        svg{
            width: 100%;
            height: 100%;
            transition: fill .3s;
        }
    }
    &.dark-theme{
        .logo-wrapper{
            svg{
                fill: white;
            }
        }
    }
}
.screen-background{
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color .7s;
}
.screen-background.white-background{
    // background-color: #EFEFEE;
    background-color: #bdbdbdff;
}
.screen-background.black-background{
    // background-color: #EFEFEE;
    background-color: #191b1d;
}
.grainy-bg-container{
    position: absolute; 
    top: 0px; 
    left: 0px; 
    width: 100%; 
    height: 120%
}
.grainy-bg-container::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 20%;
    // background: linear-gradient(to top, #D2D0CE, rgba(0, 0, 0, 0));
    background: linear-gradient(to top, #bdbdbdff, rgba(0, 0, 0, 0));
}
.sluzby-bg{
    // background-color: rgb(39, 38, 43);
    // background-color: black;
    // background-color: #212529;
    // background-color: #D2D0CE;
    background-color: #bdbdbdff;
    // color: white;
    color: black;
}
.sluzby-intro{
    position: relative;
    padding-top: 40px;
    margin-bottom: 160px;
    min-height: 600px;
    height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: radial-gradient(at center center, #2c3137 0%, #21252900 70%);
    @media screen and (max-width: 1440px) {
        height: calc(100vh + 100px);
    }
    .intro-bg-linear-gradient{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: linear-gradient(black, rgba(0,0,0,0));
        z-index: -1;
    }
    .text-rotujici{ 
        min-height: 210px;
        font-size: 210px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 1;
        @media screen and (max-width: 1440px) {
            min-height: 160px;
            font-size: 160px;
        }
        @media screen and (max-width: 992px) {
            min-height: 110px;
            font-size: 110px;
        }
    }
    .intro-p-top{
        font-size: 22px;
        font-weight: 400;
        color: RGB(130, 131, 132);
        margin-bottom: 0px;
    }
    h1{
        font-size: 130px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 1;
        @media screen and (max-width: 1440px) {
            font-size: 110px;
        }
        .h1-line{
            display: flex;
            align-items: center;
            gap: 60px;
        }
    }
    p{
        font-size: 32px;
    }
    .intro-btn-container{
        margin-top: 60px;
    }
    a.round-btn{
        display: flex;
        position: relative;
        font-size: 13px; 
        font-weight: 600;
        width: 120px;
        height: 120px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 999px;
        color: white;
        cursor: pointer;
        text-decoration: none;
        line-height: 1.3;
        .inner{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            border-radius: 999px;
            z-index: 2;
        }
        .inner-bg{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0%;
            height: 0%;
            background-color: white;
            border-radius: 999px;
            z-index: 1;
            transition: all .4s;
        }
        &:hover{
            // background-color: #ED6217;
            color: black;
            .inner-bg{
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }
        }
    }
    img{
        width: 100%;
        margin-top: 120px;
        object-fit: cover;
        aspect-ratio: 16 / 9;
    }
}
.sluzby_heading{
    color: white;
}
.sluzby-list{
    list-style: none;
    padding: 0px;
    margin: 0px auto;
    padding-bottom: 90px;
    li{
        &.black{
            color: black;
            border-bottom: solid 1px rgba(0, 0, 0, 0.2);
        }
        color: white;
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
        width: 100%;
        padding: 40px 0px;
        cursor: pointer;
        .sluzby-list-item{
            display: flex;
        }
        .sluzby-list-title{
            font-size: 60px;
            font-weight: 600;
        }
        &.disabled{
            .sluzby-list-title{
                // opacity: .35;
            }
            cursor:auto;
        }
    }
    li:first-child{
        padding-top: 0px;
    }
}
.sluzby-list-tags{
    margin-left: 20px;
    margin-top: 30px;
    display: flex;
    gap: 5px;
    .sluzby-list-tag{
        font-size: 16px;
        font-weight:600;
        line-height: 1.33;
        padding: 1px 7px;
        color: white;
        background-color: rgb(117, 0, 128);
        align-self: flex-start;
    }
}
.tag-pripravujeme-container{
    margin-left: auto;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity .3s;
}
.arrow-container{
    margin-left: auto;
    display: flex;
    align-items: center;
}
.sluzby-list li:hover .tag-pripravujeme-container{
    opacity: 1;
}
.tag-pripravujeme{
    font-size: 16px;
        font-weight:600;
        line-height: 1.33;
        padding: 1px 7px;
        color: white;
        background-color: rgba(170, 170, 175, 0.5);
}
#detail-sluzby-side-shadow{
    position: fixed;
    z-index: 9;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 205, 0);
    display: none;
    backdrop-filter: blur(0px);
    // transition: background-color 1s;
    &.active{
        display: block;
        background-color: rgba(200, 200, 205, 0.5);
        backdrop-filter: blur(10px);
    }
}
.detail-sluzby{
    overflow-y: scroll;
    position: fixed;
    z-index: 10;
    top: 0px;
    right: 0px;
    width: 70vw;
    max-width: 1600px;
    height: 100vh;
    background-color: white;
    transform: translateX(100%);
    transition: all .3s;
    @media screen and (max-width: 992px) {
        width: 100vw;
    }
    // padding: 30px 90px;
    &.active{
        transform: translateX(0px);
    }
    #detail-sluzby-controls-container{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 40px;
        height: 100%;
    }
    .detail-sluzby-controls{
        position: sticky;
        top: 16px;
        margin-bottom: 16px;
        margin-top: 16px;
        padding-left: 36px;
        z-index: 100;
    }
    .detail-sluzby-close-btn{
        cursor: pointer;
    }
}
.arrow_svg_transition{
    transition: all .4s;
}
.arrow_svg_tr_container{
    width: 70px;
    height: 70px;
    // border: solid 1px red;
    position: relative;
    overflow: hidden;
}
.arrow1_svg_tr{
    position: absolute;
    top: 0px;
    left: 0px;   
    transition: all .3s;
    fill: rgba(255, 255, 255, 0.2) !important;
}
.arrow2_svg_tr{
    position: absolute;
    top: 0px;
    left: 0px;   
    transform: translateX(-70px);
    transition: all .3s;
    // fill: white !important;
    fill: white !important;
}
.sluzby-list li:hover{
    .arrow_svg_transition{
        transform: translateX(20px);
        // fill: white !important;
        fill: black !important;
    }
    .arrow2_svg_tr{ 
        transform: translateX(-0px);
    }
    .arrow1_svg_tr{ 
        transform: translateX(70px);
    }
} 

</style>