<template>
    <div>
        <div class="block sluzba-2-intro">
            <div class="item-left">
                <div class="block-container heading-block">
                    <h1>Cokoliv, co nemusíte <br> dělat, tak nedělejte<span class="red">.</span></h1>
                </div>
            </div>
            <div class="item-right">
                <video playsinline autoplay muted loop>
                    <source src="/theme/images/sluzby/automatizace.mp4" type="video/mp4">
                </video>
            </div>
        </div>
        <div class="block block_1">
            <div class="item-left">
                <div class="background"></div>
                <img src="/theme/images/sluzby/image__20_.webp" alt="">
            </div>
            <div class="item-right">
                <div class="block-container">
                    <div class="block-text-content">
                        <h2>Umíme to rychleji, efektivněji a tedy i levněji než váš ERP systém...</h2>
                        <div class="">
                            <p>Umíme najít opakující se úkoly a procesy. Analyzujeme je a hledáme způsoby, jak je zpracovat strojově a automatizovat.</p>
                            <p>Zlepšujeme pracovní postupy a usnadňujeme práci spojením aplikací, o kterých třeba ani netušíte, že to jde. Proč? Protože to je ve svém důsledku vždy levnější...</p>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block block_sluzby">
            <div class="block-container">
                <HeadingWithLine :theme="'dark'">
                    Naše služby
                </HeadingWithLine>
                <ul>
                    <li>Nadstavba nad ERP</li>
                    <li>Automatizace firemních procesů</li>
                    <li>Automatizace e‑shopů</li>
                    <li>Webová analytika</li>
                    <li>Chytrý reporting</li>
                    <li>Vývoj interních/firemních systémů</li>
                </ul>
            </div>
        </div>
        <div class="block block_reference">
            <div class="block-container">
                <HeadingWithLine :theme="'light'">
                    Recenze
                </HeadingWithLine>
                <div class="reference-content-container">
                    <div class="reference-slide">
                        <p class="citat">"Spolupráce s firmou FOREGROUND nám ušetřila sta tisíce..."</p>
                        <div class="osoba-card">
                            <img src="" alt="">
                            <div>
                                <p class="jmeno">Ondřej Bělský</p>
                                <p class="text">funkce: CEO</p>
                            </div>
                        </div>
                    </div>
                    <div class="reference-footer">
                        <ul class="reference-tagy">
                            <li>Nadstavba nad ERP</li>
                            <li>Automatizace e-shopu</li>
                            <li>Webová analytika</li>
                            <li>Chytrý reporting</li>
                        </ul>
                        <div class="reference-paginator"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block reference_kare">
            <div class="item-left">
                <div class="block-container">
                    <div class="svg-logo">
                        <svg xmlns="http://www.w3.org/2000/svg" style="height:20px;" viewBox="0 0 169.06 30.2">
                            <g id="Vrstva_2" data-name="Vrstva 2">
                                <g id="KARE">
                                    <polygon class="cls-1" points="0 0.01 9.27 0.01 9.27 11.1 12.61 11.1 24.3 0.01 36.52 0.01 20.54 14.77 35.66 30.2 23.21 30.2 12.36 18.61 9.27 18.61 9.27 30.2 0 30.2 0 0.01"></polygon>
                                    <path class="cls-1" d="M56.17,18.34H68L62,7ZM56,.05H68l16.56,30.1H74.31l-2.87-5.32H52.71L50,30.15H39.68Z"></path>
                                    <path class="cls-1" d="M100.11,14.85h14.32c2.5,0,3.42-1,3.42-3.17v-1c0-2.67-1.34-3.17-4.26-3.17H100.11ZM90.84,0h27c7.14,0,9.06,3.54,9.06,9v1.91c0,4-.92,6.43-5.18,7.43v.08c2.76.5,5,1.71,5,6.67v5.13h-9.27V26.56c0-3.17-.91-4.21-3.79-4.21H100.11v7.84H90.84Z"></path>
                                    <polygon class="cls-1" points="136.44 0 169.06 0 169.06 7 145.71 7 145.71 12.12 167.25 12.12 167.25 17.82 145.71 17.82 145.71 22.93 169.05 22.93 169.05 30.19 136.44 30.19 136.44 0"></polygon>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <h2 class="reference_h2">Eshop s&nbsp;designovým nábytkem</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat explicabo ipsum consequuntur saepe, pariatur corporis.</p>
                </div>
            </div>
            <div class="item-right">
                <div class="background"></div>
                <img src="/theme/images/sluzby/kare.png" alt="">
            </div>
        </div>
        <div class="block reference_atex">
            <div class="item-left">
                <img src="/theme/images/sluzby/img-main-atex-shop.jpg" alt="">
            </div>
            <div class="item-right">
                <div class="block-container">
                    <div class="logo">
                        <img src="/theme/images/atex/logo.png" alt="" height="30" style="filter: invert(1);">
                    </div>
                    <h2 class="reference_h2">Heading</h2>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis animi, in et similique unde autem hic facere soluta quibusdam, minus ratione incidunt dolor ducimus asperiores molestias maiores quis, saepe dolores.</p>
                </div>
            </div>
        </div>
        <div class="block reference_sc">
            <div class="item-left">
                <div class="block-container" style="height: 100%;">
                    <div class="block-text-content">
                        <h2>SC Brno</h2>
                        <div class="text-self-end">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero quis veritatis minima maxime quae! Ea accusantium dolores odio veritatis fugit quisquam consequuntur itaque rem, animi exercitationem mollitia excepturi maxime maiores!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-right">
                
            </div>
        </div>
        <div class="sluzby-2-reference">
            <div 
                class="sluzby-reference-item reference-item-padding-lr reference-item-padding-tb"
                style="
                    background-image: url('/theme/images/sluzby/microwell_02.jpg');
                    background-size: cover;
                    aspect-ratio: 1344 / 804;
                "
            >
                <div class="reference-item-layout">
                    <h2>Microwell</h2>
                    <div class="reference-img">
                        <!-- <img src="/theme/images/sluzby/kare.png" alt=""> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeadingWithLine from '../ui/HeadingWithLine.vue';
    export default {
        components: {
            HeadingWithLine,
        },
        props: {
            sluzba_id: {
                type: Number,
            },
        },
    }
</script>

<style lang="scss" scoped>
p{
    font-size: 18px;
    line-height: 1.6;
};
.block{
    min-height: 700px;
    h2{
        font-size: 42px;
        font-weight: 600;
    }
    h2.reference_h2{
        font-weight: 600;
        font-size: 42px;
    }
}
.block-text-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    .text-self-end {
        max-width: 350px;
        align-self: flex-end;
        margin-top: auto;
    }
}
.sluzba-2-intro{
    // background-color: #cdcdcd;
    color: white;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    .item-left{
        background-color:#D6B463;
        color: black;
        .block-container{
            padding: 92px;
            padding-right: 60px;
        }
    }
    .item-right{
        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    h1{
        font-size: 72px;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 90px;
        .red{
            color: red;
        }
        @media screen and (max-width: 1600px) {
            font-size: 72px;
        }
    }
}
.block_1{
    display: grid;
    grid-template-columns: 2fr 2fr;
    .block-container{
        padding: 90px 60px;
        height: 100%;
    }
    .item-left{
        background-color: white;
        position: relative;
        padding-left: 90px;
        display: flex;
        align-items: center;
        img{
            position: relative;
            z-index: 2;
            width: 100%;
        }
        .background{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 85%;
            height: 100%;
            background-color: #bfbfbf;
        }
    }
    .item-right{
        background-color: white;
    }
}
.block_sluzby{
    background-color: black;
    color: white;
    ul{
        list-style: none;
        padding-left: 0px;
        li{
            border-bottom: solid 1px rgba(255, 255, 255, 0.20);
            text-align: center;
            font-size: 50px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
.block_reference{
    background-color: #D6B463;
    .reference-content-container{
        // padding-left: 60px;
        // padding-right: 60px;
    }
    .reference-slide{
        min-height: 440px;
        .citat{
            font-size: 50px;
            font-weight: 600;
            margin-bottom: 40px;
        }
        .osoba-card{
            display: flex;
            gap: 15px;
            align-items: center;
            img{
                background-color: grey;
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 99999px;
            }
            .jmeno{
                margin-bottom: 0px;
            }
            .text{
                font-size: 14px;
                opacity: 0.8;
                margin-bottom: 0px;
            }
        }
    }
    .reference-footer{
        display: flex;
        .reference-tagy{
            display: flex;
            gap: 6px;
            list-style: none;
            padding-left: 0px;
            margin-bottom: 0px;
            li{
                border: solid 1px black;
                border-radius: 999px;
                padding: 4px 12px;
                font-size: 16px;
            }
        }
    }

}
.reference_kare{
    display: grid;
    grid-template-columns: 1fr 1.6fr;
    .cls-1 {
        fill: #e40f1b;
    }
    .item-left{
        background-color: white;
        .block-container{
            padding-right: 10px;
        }
        .svg-logo{
            margin-bottom: 30px;
        }
        h2{
            margin-bottom: 30px;
        }
    }
    .item-right{
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 92px;
        .background{
            position: absolute;
            top: 0px;
            left: 10%;
            width: 90%;
            height: 100%;
            background-color: #bfbfbf;
        }
        img{
            position: relative;
            z-index: 2;
            width: 100%;
            transform: translateY(5%);
        }
    }
}
.reference_atex{
    display: grid;
    grid-template-columns: 3fr 1.8fr;
    .item-left{
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: #bfbfbf;
        }
    }
    .item-right{
        .block-container{
            padding-right: 32px;
            padding-left: 32px;
        }
        .logo{
            margin-bottom: 30px;
        }
        h2 {
            margin-bottom: 30px;
        }
    }
}
.reference_sc{
    display: grid;
    grid-template-columns: 2fr 1.4fr;
    .item-left{

    }
    .item-right{
        background-color: grey;
    }
}
.block-container{
    padding: 92px;
}
.block-padding-lr{
    padding-left: 92px;
    padding-right: 92px;
}
.sluzba-intro{
    background-color: #020202;
    color: white;
    h1{
        font-size: 192px;
        line-height: 1;
        font-weight: 600;
        .red{
            color: red;
        }
        @media screen and (max-width: 1600px) {
            font-size: 120px;
        }
    }
}
.line{
    border-top: #252525 solid 1px ;
}
.intro-cols{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    .first-col{
        p{
            font-size: 42px;
            line-height: 1.3;
            max-width: 500px;

            @media screen and (max-width: 1600px) {
                font-size: 32px;
            }
        }
    }
    .second-col{
        p{
            font-size: 18px;
            line-height: 1.6;
            color: #888888;
            max-width: 500px;
        };
    }
}
.sluzby-2-reference{
    background-color: #cdcdcd;
    .sluzby-reference-item{
        border-top: solid 1px rgb(192, 192, 192);
        padding-right: 0px !important;
        .reference-item-layout{
            display: grid;
            grid-template-columns: 1.2fr 2fr;
            gap: 32px;
            padding-right: 0px;
            overflow: hidden;
            img{
                max-width: 120%;
            }
        }
    }
}

.reference-item-padding-lr{
    padding-left: 92px;
    padding-right: 92px;
}
.reference-item-padding-tb{
    padding-top: 92px;
    padding-bottom: 92px;
}
.sluzby-reference-label{
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 42px;
    opacity: .4;
}
.reference-item-1{
    grid-area: item1;
    // aspect-ratio: 1;
    background-color: #202020;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    img{
        margin-top: auto;
        margin-top: 60px;
        margin-left: -50%;
        margin-right: -15%;
    }
}
.reference-item-2{
    grid-area: item2;
    background-color:white;
    color: black;

    display: flex;
    flex-direction: column;

    width: 100%;
    overflow: hidden;
    padding-bottom: 0px;
    img{
        margin-top: auto;
        margin-top: 120px;
        margin-bottom: 160px;
        margin-left: -15%;
        margin-right: -15%;
    }
}
.reference-item-3{
    grid-area: item3;
    background-color: #bfbfbf;
    color: white;

    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 32px;
    padding-right: 0px;
    overflow: hidden;

    background-position: center;
    .reference-item-3-img-wrapper{
        text-align: right;
        img{
            width: 90%;
        }
    }
}
.reference-item-3-text{
    padding-right: 60px;
}
.sluzby-reference-grid{
    display: grid;
    grid-template-areas: 
    "item1 item2"
    "item3 item3";
    img{
        max-width: 100%;
    }
    .reference-item-3 img{
        max-width: 110%;
    }
    .reference-item-2 img,
    .reference-item-1 img{
        max-width: 250%;
    }
    @media screen and (max-width: 1400px) {
        grid-template-areas: 
        "item1"
        "item2"
        "item3";
    }
}
.sluzby-reference-item{
    h2{
        font-size: 62px;
        font-weight: 600;
        margin-bottom: 42px;
    }
    p{
        line-height: 1.6;
        font-size: 18px;
        opacity: .7;
    }
}
</style>