var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "heading-w-line" }, [
    _c("h2", [_vm._t("default")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "line", class: { white: this.theme == "dark" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }