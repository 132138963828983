var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "220",
        height: "120",
        viewBox: "0 0 220 120"
      }
    },
    [
      _c(
        "g",
        { attrs: { id: "topestates", transform: "translate(44 32.999)" } },
        [
          _c("rect", {
            attrs: {
              id: "Rectangle_26",
              "data-name": "Rectangle 26",
              width: "220",
              height: "120",
              transform: "translate(-44 -32.999)",
              fill: "none"
            }
          }),
          _vm._v(" "),
          _c(
            "g",
            {
              attrs: {
                id: "topestates-2",
                "data-name": "topestates",
                transform: "translate(-158 18.685)"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group_63",
                    "data-name": "Group 63",
                    transform: "translate(131 -2)"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_118",
                      "data-name": "Path 118",
                      d:
                        "M208.876,20.395c-.589-.047-1.181-.086-1.769-.142a13.335,13.335,0,0,1-4.08-.986,5.333,5.333,0,0,1-3.313-4.563c-.053-.432-.066-.869-.1-1.332h5.966c.024.207.045.406.069.6a1.727,1.727,0,0,0,1.427,1.664,7.03,7.03,0,0,0,3.537-.032,1.045,1.045,0,0,0,.9-1.014,1.078,1.078,0,0,0-.762-1.061c-.612-.228-1.245-.408-1.877-.574-1.589-.417-3.187-.794-4.771-1.225a6.357,6.357,0,0,1-2.335-1.28,5.191,5.191,0,0,1-1.8-4.8,5.248,5.248,0,0,1,3.415-4.588,12.462,12.462,0,0,1,4.83-1.005A3.067,3.067,0,0,0,208.589,0h.358c.1.019.2.044.3.056.991.126,2,.181,2.969.389a7.247,7.247,0,0,1,3.834,2.025,5.17,5.17,0,0,1,1.36,3.944h-5.888a1.852,1.852,0,0,0-1.2-1.844,4.286,4.286,0,0,0-3.178.106,1.025,1.025,0,0,0-.74.96,1.1,1.1,0,0,0,.762,1.029,8.7,8.7,0,0,0,1.251.421c1.146.277,2.3.508,3.447.79a12.422,12.422,0,0,1,3.341,1.13,4.968,4.968,0,0,1,2.518,2.846c.094.315.162.638.242.957v1.575c-.024.1-.056.2-.07.308a5.57,5.57,0,0,1-2.647,4.234,9.727,9.727,0,0,1-4.07,1.326c-.479.056-.962.1-1.442.143Z",
                      transform: "translate(-31.988 0)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_119",
                      "data-name": "Path 119",
                      d:
                        "M93.408,20.395c-.59-.047-1.181-.086-1.77-.142a12.9,12.9,0,0,1-4.275-1.075,5.291,5.291,0,0,1-3.117-4.435c-.048-.378-.057-.76-.081-1.14a1.882,1.882,0,0,1,.014-.231h5.908c.05.311.088.615.149.914a1.6,1.6,0,0,0,1.191,1.3,6.929,6.929,0,0,0,3.845-.017.98.98,0,0,0,.754-.939.933.933,0,0,0-.564-.992,18.1,18.1,0,0,0-2.065-.679c-1.586-.424-3.189-.791-4.771-1.229a6.364,6.364,0,0,1-2.7-1.616,5.02,5.02,0,0,1-1.458-3.863,5.335,5.335,0,0,1,3.546-5.24A12.522,12.522,0,0,1,92.745.055,2.915,2.915,0,0,0,93.122,0h.286c.567.047,1.135.078,1.7.144a9.117,9.117,0,0,1,5.143,2.012,5.12,5.12,0,0,1,1.694,4.255h-5.88c-.018-.107-.039-.222-.055-.336a1.773,1.773,0,0,0-1.328-1.566,4.515,4.515,0,0,0-2.848.093c-.473.17-.9.448-.9,1.03a1.105,1.105,0,0,0,.885,1.086c1.1.318,2.209.578,3.314.867,1.174.307,2.355.593,3.518.938a6.2,6.2,0,0,1,2.478,1.517,4.742,4.742,0,0,1,1.36,3.885,5.893,5.893,0,0,1-1.741,4.254,7.189,7.189,0,0,1-3.458,1.775c-.88.19-1.786.259-2.681.384-.113.016-.226.039-.338.058Z",
                      transform: "translate(-13.487 0)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_120",
                      "data-name": "Path 120",
                      d:
                        "M183.388,15.378h10.8V20.09H177.081V.5h16.909v4.66h-10.6V7.867h9.062v4.527h-9.065Z",
                      transform: "translate(-28.377 -0.08)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_121",
                      "data-name": "Path 121",
                      d:
                        "M149.647,20.1h-.778c-1.848,0-3.7-.006-5.545.006a.384.384,0,0,1-.449-.33c-.2-.723-.433-1.436-.634-2.158a.338.338,0,0,0-.389-.3q-3.113.013-6.225,0a.362.362,0,0,0-.416.32c-.2.733-.436,1.459-.651,2.19-.052.177-.12.28-.338.28-1.992-.008-3.984-.005-5.975-.007a1.534,1.534,0,0,1-.209-.033c.044-.142.076-.265.119-.383q3.432-9.417,6.862-18.834a.5.5,0,0,1,.558-.393q3.29.024,6.583,0a.434.434,0,0,1,.488.337q3.432,9.456,6.88,18.907c.039.108.066.222.119.4m-8.821-7.21c-.686-2.335-1.359-4.626-2.032-6.917l-.109.009c-.681,2.292-1.363,4.583-2.055,6.908Z",
                      transform: "translate(-20.518 -0.073)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_122",
                      "data-name": "Path 122",
                      d:
                        "M113.563,20.107V5.518h-6.425V.5h19.124v5h-6.385V19.615c0,.49,0,.491-.474.491h-5.84Z",
                      transform: "translate(-17.169 -0.079)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_123",
                      "data-name": "Path 123",
                      d:
                        "M170.8,5.507h-6.38v.486c0,4.556-.005,9.112.008,13.669,0,.369-.1.457-.459.453q-2.7-.029-5.4,0c-.357,0-.462-.08-.46-.451.013-4.545.008-9.089.008-13.632V5.52h-6.422V.493H170.8Z",
                      transform: "translate(-24.308 -0.079)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_124",
                      "data-name": "Path 124",
                      d:
                        "M76.986,7.861v4.523H67.914v2.99H78.7v4.7H61.6V.495H78.5V5.168H67.915V7.861Z",
                      transform: "translate(-9.871 -0.079)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_125",
                      "data-name": "Path 125",
                      d:
                        "M37.792,10.137a9.623,9.623,0,0,1-6.263,9.423c-3.949,1.4-7.693.958-10.876-1.947a9.555,9.555,0,0,1-3.058-8.067,9.941,9.941,0,0,1,2.654-6.331A9.427,9.427,0,0,1,26.3.234a10.631,10.631,0,0,1,6.6,1.225,9.446,9.446,0,0,1,4.829,7.323,4.753,4.753,0,0,1,.058.5c.009.286,0,.572,0,.858m-10.2,8.688a8.37,8.37,0,0,0,8.641-8.874,8.268,8.268,0,0,0-8.492-8.346,8.38,8.38,0,0,0-8.61,8.768,8.4,8.4,0,0,0,8.46,8.452",
                      transform: "translate(-2.815 -0.024)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_126",
                      "data-name": "Path 126",
                      d:
                        "M45.776,10.969v9.069H44.291V.5a1.89,1.89,0,0,1,.255-.032C46.454.475,48.364.447,50.27.5A6,6,0,0,1,54.077,1.85,4.775,4.775,0,0,1,55.7,5.809,4.653,4.653,0,0,1,54.03,9.6a5.907,5.907,0,0,1-3.4,1.269c-1.426.09-2.858.071-4.289.1-.165,0-.331,0-.565,0M45.8,1.88V9.533c.043.024.063.045.083.045,1.6-.027,3.2.055,4.783-.111a3.454,3.454,0,0,0,3.458-3.461,3.4,3.4,0,0,0-1.891-3.612,4.792,4.792,0,0,0-1.185-.384,29.975,29.975,0,0,0-5.172-.169c-.021,0-.042.021-.076.039",
                      transform: "translate(-7.098 -0.075)"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_127",
                      "data-name": "Path 127",
                      d:
                        "M8.591,20.045H7.066V1.845H0V.5H15.708c0,.391.018.77-.016,1.145-.007.071-.22.175-.339.176-1.693.012-3.386.008-5.079.008H8.591Z",
                      transform: "translate(0 -0.081)"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }