var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "220",
        height: "120",
        viewBox: "0 0 220 120"
      }
    },
    [
      _c("g", { attrs: { id: "atex", transform: "translate(44 32.999)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_26",
            "data-name": "Rectangle 26",
            width: "220",
            height: "120",
            transform: "translate(-44 -32.999)",
            fill: "none"
          }
        }),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: {
              id: "Atex_logo",
              "data-name": "Atex logo",
              transform: "translate(-0.801 12.004)"
            }
          },
          [
            _c("path", {
              attrs: {
                id: "Path_1",
                "data-name": "Path 1",
                d:
                  "M245.755,184.5h-5.08c-5.514,0-8.733,2.857-11.21,6.635-3.218,4.936-14.265,22.473-14.265,22.473h10.4l2.73-4.737,3.507-6.039,6.364-11.011h.072l1.718,11.011,1.067,6.039.777,4.737h10.4Z",
                transform: "translate(-215.2 -184.5)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_2",
                "data-name": "Path 2",
                d:
                  "M422.466,213.709l4.683-22.6h12s-2.8,13.668-3.435,16.543c-.615,2.857-1.266,6.057-1.266,6.057h29.163l1.446-6.274H445.482s1.248-6.111,2.025-9.763,1.41-6.8,1.41-6.8h19.436l1.338-6.274H406.538l-1.338,6.527h12.258l-4.682,22.6h9.691Z",
                transform: "translate(-370.848 -184.582)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_3",
                "data-name": "Path 3",
                d:
                  "M771.068,184.5H759.515l-6.979,7.829-5.3-7.829H735.7l9.98,13.994L729.9,213.627h12.041l7.991-8.8,5.985,8.8h12.041l-11.083-15.133Z",
                transform: "translate(-636.843 -184.5)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_4",
                "data-name": "Path 4",
                d:
                  "M661.5,254.439h14.808l1.266-6.039h-8.9C661.409,248.4,661.5,254.439,661.5,254.439Z",
                transform: "translate(-580.809 -236.847)"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }