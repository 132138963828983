var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { overflow: "hidden" } }, [
      _c("div", { attrs: { id: "print-intro" } }, [
        _c("img", {
          staticClass: "intro-bg-img",
          attrs: {
            src: "/theme/images/sluzby/print/img_main_print.jpg",
            alt: ""
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "intro-text-container" }, [
          _c("h1", [
            _vm._v("Tiskoviny "),
            _c("br"),
            _c("span", [_vm._v("Print")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "intro-p left" }, [
            _c("p", [
              _vm._v(
                "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "intro-p right" }, [
            _c("p", [
              _vm._v(
                "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "print-reference" } }, [
        _c("div", { staticClass: "print-reference-1" }, [
          _c("img", {
            attrs: {
              src: "/theme/images/sluzby/print/mc_bebeautiful_denisa.png",
              alt: ""
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "reference-1-grid" }, [
            _c("div"),
            _vm._v(" "),
            _c("div", { staticClass: "print-reference-text" }, [
              _c("h2", [_vm._v("Název "), _c("br"), _vm._v(" reference")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "print-reference-2" }, [
          _c("img", {
            attrs: {
              src: "/theme/images/sluzby/print/mc_bebeautiful_sandra.png",
              alt: ""
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "print-reference-3" }, [
          _c("img", {
            attrs: {
              src: "/theme/images/sluzby/print/zivot_jako_hra.png",
              alt: ""
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "print-reference-3-grid" }, [
            _c("div"),
            _vm._v(" "),
            _c("div", { staticClass: "print-reference-text" }, [
              _c("h2", [_vm._v("Název reference")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "print-reference-4" }, [
          _c("div", { staticClass: "print-reference-text" }, [
            _c("h2", [_vm._v("Název reference")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
              )
            ])
          ]),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: "/theme/images/sluzby/print/top_estates.png",
              alt: ""
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "print-reference-5" }, [
          _c("img", {
            attrs: { src: "/theme/images/sluzby/print/irebook.png", alt: "" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "print-reference-5-grid" }, [
            _c("div"),
            _vm._v(" "),
            _c("div", { staticClass: "print-reference-text" }, [
              _c("h2", [_vm._v("IREBOOK")]),
              _vm._v(" "),
              _c("p", { staticClass: "subtitle" }, [
                _vm._v("Kniha firmní kulury")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "print-reference-6" }, [
          _c("img", {
            attrs: {
              src: "/theme/images/sluzby/print/cofee_katalog_2024.png",
              alt: ""
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "print-reference-text" }, [
            _c("h2", [_vm._v("coFEE"), _c("br"), _vm._v("Katalog")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny."
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }