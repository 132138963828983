var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "caruselContainer", staticClass: "carusel-container" },
    [
      _c("div", { staticClass: "carousel-sticky-container" }, [
        _c("div", { staticClass: "carousel-heading" }, [_vm._t("heading")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "carusel-wrapper" }, [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }