var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "220",
        height: "120",
        viewBox: "0 0 220 120"
      }
    },
    [
      _c("g", { attrs: { id: "brand", transform: "translate(34 32.999)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_26",
            "data-name": "Rectangle 26",
            width: "220",
            height: "120",
            transform: "translate(-34 -32.999)",
            fill: "none"
          }
        }),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: { id: "logo_brand", transform: "translate(-17.048 -1.46)" }
          },
          [
            _c("path", {
              attrs: {
                id: "Path_1",
                "data-name": "Path 1",
                d: "M0,0V50.538H186.687V0ZM185.177,49.048H1.489V1.489H185.177Z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_2",
                "data-name": "Path 2",
                d:
                  "M8.969,21.574a4.636,4.636,0,0,0,3.638,1.4,5.339,5.339,0,1,0,0-10.635A4.7,4.7,0,0,0,9.012,13.81V9.28H5.8V22.574H8.969Zm2.977-6.785a2.85,2.85,0,0,1,3,2.914,2.808,2.808,0,0,1-3,2.85,2.829,2.829,0,0,1-3.063-2.914,2.893,2.893,0,0,1,3.063-2.85Z",
                transform: "translate(6.536 10.458)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_3",
                "data-name": "Path 3",
                d:
                  "M15.588,16.246a2.127,2.127,0,0,1,.829-2,3.148,3.148,0,0,1,1.893-.574,2.127,2.127,0,0,1,.829.127h.149V10.717h-.3a3.36,3.36,0,0,0-3.4,1.723v-1.4H12.44v9.956h3.148Z",
                transform: "translate(14.02 12.042)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_4",
                "data-name": "Path 4",
                d:
                  "M21.807,14.911a11.231,11.231,0,0,0-4,.681A2.85,2.85,0,0,0,16,18.291c0,1.936,1.574,3.127,4.084,3.127a5.87,5.87,0,0,0,4.254-1.447l.234,1.043H27.72v-.4h0c-.191-.256-.256-.681-.256-1.68V14.8c0-1.595-.256-2.319-1.043-2.977a6.955,6.955,0,0,0-4.254-1.149,6.381,6.381,0,0,0-4.021,1.149,3,3,0,0,0-1.382,2.489v.127H20.1c0-.574.17-.808.468-.914h0a2.637,2.637,0,0,1,1.7-.447c1.149,0,1.978.4,1.978.978S23.785,14.7,21.807,14.911Zm2.34,1.765a2.127,2.127,0,0,1-.574,1.617,4.615,4.615,0,0,1-2.616.851c-.978,0-1.595-.383-1.595-.978a.914.914,0,0,1,.681-.914,7.764,7.764,0,0,1,1.191-.277l.851-.127a6.147,6.147,0,0,0,2.127-.468Z",
                transform: "translate(18.032 12.019)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_5",
                "data-name": "Path 5",
                d:
                  "M25.583,16.041a3.765,3.765,0,0,1,.256-1.638,2.7,2.7,0,0,1,2.276-1.149,2.34,2.34,0,0,1,1.659.639,2.255,2.255,0,0,1,.34,1.7V21.06h3.212V14.828a4.615,4.615,0,0,0-.468-2.552A4.424,4.424,0,0,0,29.263,10.7a4.722,4.722,0,0,0-2.681.745,8.38,8.38,0,0,0-1.043.957v-1.38H22.35v9.956h3.233Z",
                transform: "translate(25.189 12.056)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_6",
                "data-name": "Path 6",
                d:
                  "M34.081,22.978a4.615,4.615,0,0,0,3.595-1.4v1h3.233V9.28H37.59v4.488a4.636,4.636,0,0,0-3.51-1.468,5.339,5.339,0,1,0,0,10.635Zm.617-8.189a2.893,2.893,0,1,1,0,5.764,2.744,2.744,0,0,1-2.935-2.85,2.808,2.808,0,0,1,2.855-2.914Z",
                transform: "translate(31.856 10.458)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_7",
                "data-name": "Path 7",
                d:
                  "M39.589,19.3a4.382,4.382,0,0,0,3.935,1.915A5.1,5.1,0,0,0,48.9,16.007,5.041,5.041,0,0,0,43.566,10.8a4.787,4.787,0,0,0-3.935,1.765V11.007H37.76v13.1h1.872Zm3.658-6.893a3.424,3.424,0,0,1,3.531,3.658,3.382,3.382,0,0,1-3.595,3.467,3.595,3.595,0,0,1-3.722-3.595,3.467,3.467,0,0,1,3.786-3.531Z",
                transform: "translate(42.556 12.156)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_8",
                "data-name": "Path 8",
                d:
                  "M45.772,15.978a3.085,3.085,0,0,1,.723-2.276A3,3,0,0,1,48.9,12.6a4.573,4.573,0,0,1,1.191.191h.129V10.83h0a5.764,5.764,0,0,0-.957,0,3.127,3.127,0,0,0-2.3.766,5.531,5.531,0,0,0-1.106,1.425V11.044H43.9v9.869h1.872Z",
                transform: "translate(49.475 12.183)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_9",
                "data-name": "Path 9",
                d:
                  "M52.86,21.288a5.127,5.127,0,0,0,5.679-5.148,5.211,5.211,0,0,0-5.616-5.4,5.339,5.339,0,0,0-5.744,5.275,5.211,5.211,0,0,0,5.679,5.275Zm0-8.87a3.36,3.36,0,0,1,3.53,3.659,3.3,3.3,0,0,1-3.531,3.595,3.829,3.829,0,0,1-2.681-.978,3.489,3.489,0,0,1-.978-2.616,3.51,3.51,0,0,1,3.722-3.658Z",
                transform: "translate(53.171 12.085)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_10",
                "data-name": "Path 10",
                d:
                  "M55.431,15.545a2.723,2.723,0,0,1,.829-2.34,3.4,3.4,0,0,1,2.276-.723,2.127,2.127,0,0,1,2.255,1.212A4.063,4.063,0,0,1,61,15.332v5.613h1.875v-5.4a3,3,0,0,1,.447-1.893,3.063,3.063,0,0,1,2.616-1.107c1.34,0,2.276.51,2.51,1.4a10.632,10.632,0,0,1,0,1.595V21.01h1.957V14.843a3.807,3.807,0,0,0-.34-2.127,3.743,3.743,0,0,0-3.658-1.957A4.041,4.041,0,0,0,62.6,12.695a3.638,3.638,0,0,0-3.51-1.936,4.53,4.53,0,0,0-3.727,1.762V10.972H53.41v9.869h1.957Z",
                transform: "translate(60.193 12.106)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_11",
                "data-name": "Path 11",
                d:
                  "M68.044,21.29a5.148,5.148,0,0,0,5.679-5.148,5.211,5.211,0,0,0-5.616-5.4A5.361,5.361,0,0,0,62.3,16.015a5.233,5.233,0,0,0,5.744,5.275Zm0-8.87a3.36,3.36,0,0,1,3.531,3.658,3.318,3.318,0,0,1-3.531,3.595,3.659,3.659,0,0,1-2.7-.978A3.36,3.36,0,0,1,64.3,16.078a3.552,3.552,0,0,1,3.849-3.654Z",
                transform: "translate(70.212 12.083)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_12",
                "data-name": "Path 12",
                d:
                  "M69.617,19.775c0,1.3,0,1.361.213,1.723a3.018,3.018,0,0,0,2.446,1,7.339,7.339,0,0,0,1.212,0h.362V20.881h-.127a5.615,5.615,0,0,1-1.106,0c-.914,0-1.128-.191-1.128-1.043V13.882h2.425V12.351H71.489V9.65H69.617v2.637H68v1.527h1.617Z",
                transform: "translate(76.636 10.875)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_5",
                "data-name": "Rectangle 5",
                width: "1.893",
                height: "2.084",
                transform: "translate(152.165 19.738)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_6",
                "data-name": "Rectangle 6",
                width: "1.893",
                height: "9.89",
                transform: "translate(152.165 23.163)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_13",
                "data-name": "Path 13",
                d:
                  "M79.011,21.289a5.211,5.211,0,0,0,5.683-5.148h0a5.317,5.317,0,0,0-5.679-5.4,5.254,5.254,0,0,0-5.679,5.275,5.148,5.148,0,0,0,5.679,5.275Zm-3.595-5.211a3.574,3.574,0,0,1,1.021-2.637,3.616,3.616,0,0,1,2.574-1.017,3.382,3.382,0,0,1,3.595,3.658,3.36,3.36,0,0,1-3.595,3.595A4.021,4.021,0,0,1,76.33,18.7a3.212,3.212,0,0,1-.914-2.616Z",
                transform: "translate(82.642 12.084)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_14",
                "data-name": "Path 14",
                d:
                  "M81.547,16.016a5.275,5.275,0,0,1,.319-2.127,3,3,0,0,1,2.85-1.468c1.382,0,2.127.383,2.467,1.212a4.893,4.893,0,0,1,.191,1.851v5.4h1.895V15.208a5.872,5.872,0,0,0-.3-2.659A3.743,3.743,0,0,0,85.333,10.8a4.254,4.254,0,0,0-3.871,1.829V11.017H79.59v9.869h1.872Z",
                transform: "translate(89.699 12.146)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_15",
                "data-name": "Path 15",
                d:
                  "M60.061,33.67v-3.3a1.786,1.786,0,0,0-.66-1.4,4.254,4.254,0,0,0-2.127-.468h-.149c-1.744,0-2.681.745-2.744,2.021h1.277a1.043,1.043,0,0,1,.468-.808,1.7,1.7,0,0,1,1-.277h.149a2.127,2.127,0,0,1,1.064.213.639.639,0,0,1,.4.468,2.126,2.126,0,0,1,0,.723,9.094,9.094,0,0,0-1.68.213h-.4a9.422,9.422,0,0,0-1.128.277,2.319,2.319,0,0,0-1.085.6,1.468,1.468,0,0,0-.4,1.085,1.511,1.511,0,0,0,.617,1.255,2.787,2.787,0,0,0,1.744.489,1.765,1.765,0,0,0,.66,0,3.339,3.339,0,0,0,1.786-.936.723.723,0,0,0,.34.681,1.447,1.447,0,0,0,.787.191,3.489,3.489,0,0,0,.745,0v-.872h-.383S60.129,33.82,60.061,33.67ZM58.7,32.458a.914.914,0,0,1-.256.681,1.872,1.872,0,0,1-1.425.66h-.4a1.915,1.915,0,0,1-.936-.191.766.766,0,0,1-.4-.681.66.66,0,0,1,.4-.6,4.743,4.743,0,0,1,.808-.4h.531a2.127,2.127,0,0,1,.617,0,6.872,6.872,0,0,1,1.064-.149Z",
                transform: "translate(60.902 32.118)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_16",
                "data-name": "Path 16",
                d:
                  "M62.478,30.374a2.127,2.127,0,0,0-.957-.617,3.382,3.382,0,0,0-.808-.127h-.256a4.126,4.126,0,0,0-1.212.256,1.723,1.723,0,0,0-.872.681,2.659,2.659,0,0,0-.617,1,2.85,2.85,0,0,0-.256,1.276,3.3,3.3,0,0,0,.191,1.212,2.744,2.744,0,0,0,.617,1.021,2.127,2.127,0,0,0,.936.6,3.339,3.339,0,0,0,1.149.213h.3a2.255,2.255,0,0,0,1.765-.957v.81h1.191V27.55H62.457Zm-.149,3.552a2.425,2.425,0,0,1-.66.745,2.34,2.34,0,0,1-.978.213,2.4,2.4,0,0,1-1-.191,2.489,2.489,0,0,1-.681-.745,3.382,3.382,0,0,1-.191-1.212,2.531,2.531,0,0,1,.468-1.617,1.786,1.786,0,0,1,1.4-.6,1.532,1.532,0,0,1,.957.277,1.468,1.468,0,0,1,.66.723,3.021,3.021,0,0,1,.213,1.212,2.956,2.956,0,0,1-.191,1.191Z",
                transform: "translate(64.802 31.049)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_17",
                "data-name": "Path 17",
                d:
                  "M49.127,31.319a2.4,2.4,0,0,0,.936-.531,1.6,1.6,0,0,0,.277-1.021,1.978,1.978,0,0,0-.149-.936,2.127,2.127,0,0,0-.6-.66,2.489,2.489,0,0,0-.936-.425,3.446,3.446,0,0,0-1.212-.191,3.36,3.36,0,0,0-2.616,1,1.34,1.34,0,0,0-.4.681,3.446,3.446,0,0,0-.127,1h1.276a2.127,2.127,0,0,1,.468-1.276,2.531,2.531,0,0,1,1.34-.319A2.127,2.127,0,0,1,48.6,28.9a1.064,1.064,0,0,1,.468.872,1,1,0,0,1-.213.745,1.7,1.7,0,0,1-.723.4,9.192,9.192,0,0,1-1.489,0v.936A7,7,0,0,1,48.128,32a2.276,2.276,0,0,1,.936.4,1.128,1.128,0,0,1,.34.808,1.382,1.382,0,0,1-.553,1.128,2.319,2.319,0,0,1-1.468.4,1.839,1.839,0,0,1-2.127-1.68H44a4,4,0,0,0,.127,1.021,2.127,2.127,0,0,0,.4.66A3.467,3.467,0,0,0,47.3,35.767a3.7,3.7,0,0,0,1.744-.34,2.489,2.489,0,0,0,1.212-.872,2.361,2.361,0,0,0,.4-1.34,1.893,1.893,0,0,0-1.532-1.893Z",
                transform: "translate(49.588 31.045)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_18",
                "data-name": "Path 18",
                d:
                  "M64.177,33.226,62.283,28.59h-1.4L63.5,34.5h1.212l2.765-5.913H66.049Z",
                transform: "translate(68.612 32.221)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_7",
                "data-name": "Rectangle 7",
                width: "1.212",
                height: "1.212",
                transform: "translate(162.609 58.599)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_19",
                "data-name": "Path 19",
                d:
                  "M54.315,28.114a3.552,3.552,0,0,0-1.212-.489,5.871,5.871,0,0,0-1.149,0,2.85,2.85,0,0,0-.745,0H47.93V35.75h4.021a5.4,5.4,0,0,0,1.276-.191,3.105,3.105,0,0,0,1.149-.468,3.041,3.041,0,0,0,.872-.936,3.489,3.489,0,0,0,.6-1.149,4.255,4.255,0,0,0,.149-1.425A3.722,3.722,0,0,0,54.315,28.114Zm-1.021,6.1a2.765,2.765,0,0,1-1.34.34,2.383,2.383,0,0,1-.681,0h-2V28.582h2.681a1.3,1.3,0,0,1,.531,0,3.658,3.658,0,0,1,.893.256,2.85,2.85,0,0,1,1.191,2.637,2.723,2.723,0,0,1-1.276,2.744Z",
                transform: "translate(54.017 31.101)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_20",
                "data-name": "Path 20",
                d:
                  "M82.656,28.841a2.744,2.744,0,0,0-1.361-.34,2.978,2.978,0,0,0-1.128.191,4.722,4.722,0,0,0-1.085.745v-.746H77.87V34.6h1.212V31.52a4.741,4.741,0,0,1,.127-1.149,1.7,1.7,0,0,1,.681-.66,1.915,1.915,0,0,1,1.085-.277,1.489,1.489,0,0,1,1.064.34,1.872,1.872,0,0,1,.341,1.214V34.6h1.212V30.032a1.957,1.957,0,0,0-.127-.468A1.51,1.51,0,0,0,82.656,28.841Z",
                transform: "translate(87.76 32.119)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_21",
                "data-name": "Path 21",
                d:
                  "M86.134,29.5a2.255,2.255,0,0,0-1.807-.936H84.05a3.361,3.361,0,0,0-1.149.256,2.4,2.4,0,0,0-.936.617,7.592,7.592,0,0,0-.531,1,3.936,3.936,0,0,0-.213,1.34,3.616,3.616,0,0,0,.213,1.3,2.594,2.594,0,0,0,.531.936,2.659,2.659,0,0,0,.872.6,3.722,3.722,0,0,0,1.212.213h.277a1.424,1.424,0,0,0,.787-.213,2.872,2.872,0,0,0,.957-.851,3.659,3.659,0,0,1-.213,1.34.893.893,0,0,1-.531.66,2.127,2.127,0,0,1-1,.213h0a1.7,1.7,0,0,1-1-.213.829.829,0,0,1-.468-.66H81.584a2.127,2.127,0,0,0,.4,1,2.255,2.255,0,0,0,.936.617,4.382,4.382,0,0,0,1.4.191h0a4.573,4.573,0,0,0,1.595-.277,2,2,0,0,0,.957-.787,4.083,4.083,0,0,0,.4-1.021,7.85,7.85,0,0,0,.127-1.276V28.624H86.134Zm-.213,3.3a1.532,1.532,0,0,1-.66.745,1.468,1.468,0,0,1-.936.256,1.659,1.659,0,0,1-.957-.256,1.425,1.425,0,0,1-.6-.745,3.34,3.34,0,0,1-.213-1.149,2.446,2.446,0,0,1,.489-1.617,1.574,1.574,0,0,1,1.276-.6,1.808,1.808,0,0,1,.936.277,1.915,1.915,0,0,1,.66.808,2.531,2.531,0,0,1,.213,1.128,2.914,2.914,0,0,1-.213,1.085Z",
                transform: "translate(91.535 32.187)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_22",
                "data-name": "Path 22",
                d:
                  "M78.279,31.842a5.17,5.17,0,0,0-.957-.4l-2.127-.681c-.4-.191-.66-.34-.66-.531A.6.6,0,0,1,75,29.61a2,2,0,0,1,1-.213c.936,0,1.4.34,1.489.936h1.191a1.6,1.6,0,0,0-.256-.936,1.723,1.723,0,0,0-.936-.681,4.722,4.722,0,0,0-1.422-.257,4.6,4.6,0,0,0-1.4.256,1.915,1.915,0,0,0-.936.617,1.085,1.085,0,0,0-.34.872,1.127,1.127,0,0,0,.256.808,2.531,2.531,0,0,0,1.021.531l2.276.808a4.251,4.251,0,0,1,.553.277.51.51,0,0,1,.127.34.639.639,0,0,1-.4.6,3.085,3.085,0,0,1-1.149.191,1.893,1.893,0,0,1-1.212-.256.957.957,0,0,1-.4-.872h-1.34a2.127,2.127,0,0,0,.34,1.128,2.681,2.681,0,0,0,1,.745A5.361,5.361,0,0,0,76,34.712a5.7,5.7,0,0,0,1.553-.213,2.808,2.808,0,0,0,1-.66,1.6,1.6,0,0,0,.4-1.021,2.126,2.126,0,0,0-.127-.66A2.913,2.913,0,0,0,78.279,31.842Z",
                transform: "translate(82.396 32.075)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_8",
                "data-name": "Rectangle 8",
                width: "1.212",
                height: "5.913",
                transform: "translate(162.609 60.811)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_9",
                "data-name": "Rectangle 9",
                width: "1.276",
                height: "1.212",
                transform: "translate(152.804 58.599)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_23",
                "data-name": "Path 23",
                d:
                  "M69.978,28.706a4.255,4.255,0,0,0-.808.893v-.957H68v5.913h1.276V31.642a2.638,2.638,0,0,1,.341-1.468,1.851,1.851,0,0,1,1.553-.531h.4V28.557a3.486,3.486,0,0,1-.468,0,2.4,2.4,0,0,0-1.128.148Z",
                transform: "translate(76.636 32.169)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_24",
                "data-name": "Path 24",
                d:
                  "M69.931,29.309a2.489,2.489,0,0,0-1-.617,4.254,4.254,0,0,0-1.34-.191,7.061,7.061,0,0,0-1.361.256,2.914,2.914,0,0,0-1.064.617,2.786,2.786,0,0,0-.681,1,3.786,3.786,0,0,0-.256,1.276,3.127,3.127,0,0,0,1.532,2.765,4.7,4.7,0,0,0,1.744.34h0a3.659,3.659,0,0,0,1.4-.277,2.85,2.85,0,0,0,1.212-.66,3.574,3.574,0,0,0,.6-1.128H69.442c-.213.808-.808,1.128-1.808,1.128a1.957,1.957,0,0,1-1.489-.468,1.617,1.617,0,0,1-.531-1.34h5.17a1.531,1.531,0,0,0,0-.531,3.446,3.446,0,0,0-.191-1.212,3.233,3.233,0,0,0-.66-.957Zm-2.34,1.744H65.637a1.723,1.723,0,0,1,1.957-1.617,2.127,2.127,0,0,1,1,.213,1.34,1.34,0,0,1,.6.531,1.744,1.744,0,0,1,.34.872Z",
                transform: "translate(72.387 32.119)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_10",
                "data-name": "Rectangle 10",
                width: "1.276",
                height: "5.913",
                transform: "translate(152.804 60.811)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_25",
                "data-name": "Path 25",
                d:
                  "M71.983,27.9H70.707v1.468H69.75v.872h.957v3.574a3.317,3.317,0,0,0,.127,1,1.17,1.17,0,0,0,.468.531,3.17,3.17,0,0,0,.936.149,3.679,3.679,0,0,0,.872,0v-1h-.383a1.361,1.361,0,0,1-.617-.149c-.127-.127-.127-.34-.127-.6V30.24H73.11v-.808H71.983Z",
                transform: "translate(78.608 31.444)"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }