<template>
    <div>
        <footer>
            <!-- <CTA :theme="theme"></CTA> -->
            <div id="detail-sluzby-side-shadow"></div>
            <div id="cta-side">
                <div id="cta-side-wrapper">
                    <div id="cta-side-controls-container">
                        <div class="detail-sluzby-controls">
                            <div 
                                style="position:relative;width:20px;height: 20px;"
                                @click="skrytCTA()"
                                class="detail-sluzby-close-btn"
                            >
                                <span class="ox-toggler__bar" style="position:absolute; transform: rotate(-45deg);top:50%;left:0;"></span>
                                <span class="ox-toggler__bar" style="position:absolute; transform: rotate(45deg);top:50%;left:0;"></span>
                            </div>
                        </div>
                    </div>
                    <CTAForm></CTAForm>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import CTAForm from './CTAForm.vue';
import CTA from './CTA.vue';
    export default {
        props:{
            theme: {
                type: String,
                default: "light"
            },
        },
        components: {
            CTAForm, CTA
        },
        methods: {
            zobrazitCTA() {
                document.getElementById("cta-side").classList.add("active")
                document.getElementById("detail-sluzby-side-shadow").classList.add("active")
                document.body.style.overflow = "hidden";
            },
            skrytCTA(){
                // window.history.pushState(null, null, `/sluzby`)
                document.getElementById("cta-side").classList.remove("active")
                // document.getElementById("detail-sluzby-side-shadow").classList.remove("active")
                document.body.style.overflow = "scroll";
            }
        },
    }
</script>

<style lang="scss" scoped>
footer{
    #detail-sluzby-side-shadow{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background-color: rgba(200, 200, 200, 0);
        display: none;
        backdrop-filter: blur(0px);
        // transition: background-color 1s;
        &.active{
            display: block;
            background-color: rgba(200, 200, 205, 0.5);
            backdrop-filter: blur(10px);
        }
    }
    #cta-side{
        overflow-y: scroll;
        position: fixed;
        z-index: 10;
        top: 0px;
        right: 0px;
        width: 70vw;
        max-width: 1600px;
        height: 100vh;
        background-color: white;
        transform: translateX(100%);
        transition: all .3s;
        @media screen and (max-width: 992px) {
            width: 100vw;
        }
        // padding: 30px 90px;
        &.active{
            transform: translateX(0px);
        }
        .detail-sluzby-controls{
            position: absolute;
            margin-bottom: 16px;
            margin-top: 16px;
            padding-left: 32px;
        }
        .detail-sluzby-close-btn{
            cursor: pointer;
        }
        #cta-side-controls-container{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 40px;
        height: 100%;
        }
        .detail-sluzby-controls{
            position: sticky;
            top: 16px;
            margin-bottom: 16px;
            margin-top: 16px;
            padding-left: 36px;
            z-index: 100;
        }
    }
    padding: 0px;
}

</style>