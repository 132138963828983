var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sluzby-bg" }, [
      _c("div", {
        staticClass: "screen-background",
        class: { "black-background": _vm.isScrolled }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sluzby-intro", staticStyle: { position: "relative" } },
        [
          _c("div", { staticClass: "grainy-bg-container" }, [
            _c(
              "svg",
              {
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  version: "1.1",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  "xmlns:svgjs": "http://svgjs.dev/svgjs"
                }
              },
              [
                _c(
                  "defs",
                  [
                    _c(
                      "linearGradient",
                      {
                        attrs: {
                          gradientTransform: "rotate(-214, 0.5, 0.5)",
                          x1: "50%",
                          y1: "0%",
                          x2: "50%",
                          y2: "100%",
                          id: "gggrain-gradient2"
                        }
                      },
                      [
                        _c("stop", {
                          attrs: {
                            "stop-color": "hsla(36, 0%, 74%, 1.00)",
                            "stop-opacity": "1",
                            offset: "-0%"
                          }
                        }),
                        _c("stop", {
                          attrs: {
                            "stop-color": "rgba(255,255,255,0)",
                            "stop-opacity": "0",
                            offset: "100%"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "linearGradient",
                      {
                        attrs: {
                          gradientTransform: "rotate(214, 0.5, 0.5)",
                          x1: "50%",
                          y1: "0%",
                          x2: "50%",
                          y2: "100%",
                          id: "gggrain-gradient3"
                        }
                      },
                      [
                        _c("stop", {
                          attrs: {
                            "stop-color": "hsl(0, 0%, 87%)",
                            "stop-opacity": "1"
                          }
                        }),
                        _c("stop", {
                          attrs: {
                            "stop-color": "rgba(255,255,255,0)",
                            "stop-opacity": "0",
                            offset: "100%"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "filter",
                      {
                        attrs: {
                          id: "gggrain-filter",
                          x: "-20%",
                          y: "-20%",
                          width: "140%",
                          height: "140%",
                          filterUnits: "objectBoundingBox",
                          primitiveUnits: "userSpaceOnUse",
                          "color-interpolation-filters": "sRGB"
                        }
                      },
                      [
                        _c("feTurbulence", {
                          attrs: {
                            type: "fractalNoise",
                            baseFrequency: "0.53",
                            numOctaves: "2",
                            seed: "2",
                            stitchTiles: "stitch",
                            x: "0%",
                            y: "0%",
                            width: "100%",
                            height: "100%",
                            result: "turbulence"
                          }
                        }),
                        _vm._v(" "),
                        _c("feColorMatrix", {
                          attrs: {
                            type: "saturate",
                            values: "0",
                            x: "0%",
                            y: "0%",
                            width: "100%",
                            height: "100%",
                            in: "turbulence",
                            result: "colormatrix"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "feComponentTransfer",
                          {
                            attrs: {
                              x: "0%",
                              y: "0%",
                              width: "100%",
                              height: "100%",
                              in: "colormatrix",
                              result: "componentTransfer"
                            }
                          },
                          [
                            _c("feFuncR", {
                              attrs: { type: "linear", slope: "3" }
                            }),
                            _vm._v(" "),
                            _c("feFuncG", {
                              attrs: { type: "linear", slope: "3" }
                            }),
                            _vm._v(" "),
                            _c("feFuncB", {
                              attrs: { type: "linear", slope: "3" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("feColorMatrix", {
                          attrs: {
                            x: "0%",
                            y: "0%",
                            width: "100%",
                            height: "100%",
                            in: "componentTransfer",
                            result: "colormatrix2",
                            type: "matrix",
                            values:
                              "1 0 0 0 0\n                            0 1 0 0 0\n                            0 0 1 0 0\n                            0 0 0 17 -9"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("g", [
                  _c("rect", {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      fill: "hsl(220, 1%, 95%)"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      fill: "url(#gggrain-gradient3)"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      fill: "url(#gggrain-gradient2)"
                    }
                  }),
                  _c("rect", {
                    staticStyle: { "mix-blend-mode": "soft-light" },
                    attrs: {
                      width: "100%",
                      height: "100%",
                      fill: "transparent",
                      filter: "url(#gggrain-filter)",
                      opacity: "0.57"
                    }
                  })
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "intro-heading-container",
              staticStyle: { position: "relative", "z-index": "2" }
            },
            [
              _c("div", { staticClass: "container" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("p", { staticClass: "text-rotujici" }, [
                  _vm._v(_vm._s(_vm.subtitleDisplayText))
                ]),
                _vm._v(" "),
                _vm._m(1)
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "container",
              staticStyle: { position: "relative", "z-index": "2" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "loga-container",
                  class: { "dark-theme": _vm.isScrolled }
                },
                [
                  _c(
                    "div",
                    { staticClass: "logo-wrapper" },
                    [_c("logo-loreal")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo-wrapper" },
                    [_c("logo-medicom")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo-wrapper" },
                    [_c("LogoAtex")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo-wrapper" },
                    [_c("logo-topestates")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo-wrapper" },
                    [_c("LogoPZK")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo-wrapper" },
                    [_c("LogoBrand")],
                    1
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { position: "relative", "z-index": "2" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sluzby-wrapper",
          staticStyle: { position: "relative", "z-index": "2" },
          attrs: { id: "sluzby" }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("HeadingWithLine", { attrs: { theme: "dark" } }, [
                _c("span", { staticClass: "sluzby_heading" }, [
                  _vm._v("Služby")
                ])
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "sluzby-list" },
                _vm._l(_vm.sluzby, function(sluzba) {
                  return _c(
                    "li",
                    {
                      key: sluzba.id,
                      staticClass: "scroll-efekt",
                      class: { disabled: sluzba.disabled },
                      on: {
                        click: function($event) {
                          return _vm.zobrazitDetailSluzby(sluzba)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "sluzby-list-item" }, [
                        _c("span", { staticClass: "sluzby-list-title" }, [
                          _vm._v(_vm._s(sluzba.name))
                        ]),
                        _vm._v(" "),
                        sluzba.tags
                          ? _c(
                              "span",
                              { staticClass: "sluzby-list-tags" },
                              _vm._l(sluzba.tags, function(tag) {
                                return _c(
                                  "span",
                                  { key: tag, staticClass: "sluzby-list-tag" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(tag) +
                                        " \n                                "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        sluzba.disabled
                          ? _c(
                              "span",
                              { staticClass: "tag-pripravujeme-container" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "tag-pripravujeme" },
                                  [_vm._v("Připravujeme prezentaci")]
                                )
                              ]
                            )
                          : _c("span", { staticClass: "arrow-container" }, [
                              _c(
                                "div",
                                { staticClass: "arrow_svg_tr_container" },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "arrow1_svg_tr",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        height: "70px",
                                        viewBox: "0 -960 960 960",
                                        width: "70px",
                                        fill: "#5f6368"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "arrow2_svg_tr",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        height: "70px",
                                        viewBox: "0 -960 960 960",
                                        width: "70px",
                                        fill: "#5f6368"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ])
                      ])
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "detail-sluzby-side-shadow" } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detail-sluzby", attrs: { id: "detail-sluzby-side" } },
      [
        _c(
          "div",
          { attrs: { id: "detail-sluzby-side-wrapper" } },
          [
            _c("div", { attrs: { id: "detail-sluzby-controls-container" } }, [
              _c(
                "div",
                {
                  staticClass: "detail-sluzby-controls",
                  attrs: { id: "detail-sluzby-controls" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "detail-sluzby-close-btn",
                      staticStyle: {
                        position: "relative",
                        width: "20px",
                        height: "20px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.skrytDetalSluzby()
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "ox-toggler__bar",
                        staticStyle: {
                          position: "absolute",
                          transform: "rotate(-45deg)",
                          top: "50%",
                          left: "0"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "ox-toggler__bar",
                        staticStyle: {
                          position: "absolute",
                          transform: "rotate(45deg)",
                          top: "50%",
                          left: "0"
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("sluzba-detail", {
              attrs: { sluzba: this.detailSluzby, sluzbaSide: true }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { position: "relative", "z-index": "3" } },
      [_c("CTA", { attrs: { theme: "dark" } })],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _c("span", { staticClass: "h1-line" }, [
        _c("span", { staticClass: "hp_h1_efekt" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "intro-subtitle-container" }, [
      _c("div", { staticClass: "intro-line-1" }, [
        _c("p", { staticClass: "intro-p-top" }, [
          _vm._v(
            "Jsme reklamní agentura zajišťující komplexní služby v oblasti reklamy a marketingu. "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "line" }),
        _vm._v(" "),
        _c("a", { attrs: { href: "#sluzby" } }, [
          _c("button", [_vm._v("Naše služby")])
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("p", { staticClass: "intro-p-top" }, [
          _vm._v(
            "\n                                Pomáháme klientům zorientovat se v rychle vyvíjejícím světě marketingu a společně "
          ),
          _c("br"),
          _vm._v(
            "\n                                nacházíme řešení, která dávají smysl, fungují a přinášejí výsledky.\n                            "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }