var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "220",
        height: "120",
        viewBox: "0 0 220 120"
      }
    },
    [
      _c("g", { attrs: { id: "pzk", transform: "translate(44 32.999)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_26",
            "data-name": "Rectangle 26",
            width: "220",
            height: "120",
            transform: "translate(-44 -32.999)",
            fill: "none"
          }
        }),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: {
              id: "Group_44",
              "data-name": "Group 44",
              transform: "translate(8.438 3.741)"
            }
          },
          [
            _c("path", {
              attrs: {
                id: "Path_10",
                "data-name": "Path 10",
                d:
                  "M0,6.662c.006,0,4.517-.14,8.216-.14,3.736,0,6.376.672,8.165,2.219a8.75,8.75,0,0,1,2.863,6.7,9.654,9.654,0,0,1-2.466,6.871c-2.068,2.106-5.128,3.051-8.708,3.051-.794,0-1.4,0-2.066-.015V35.589H0ZM6,20.283a15.245,15.245,0,0,0,2.027.056c3.221,0,5.209-1.761,5.209-4.725,0-2.663-1.71-4.254-4.733-4.254A18.758,18.758,0,0,0,6,11.5Z",
                transform: "translate(0 -3.461)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_11",
                "data-name": "Path 11",
                d:
                  "M41.979,32.194,54.741,12.347v-.172H43.171V6.761H62.613v3.782L50.13,30.13V30.3H62.812v5.412H41.979Z",
                transform: "translate(-22.274 -3.588)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_12",
                "data-name": "Path 12",
                d:
                  "M98.379,6.762h6v12.8h.119c.6-1.116,1.233-2.148,1.828-3.178l6.082-9.624h7.435l-8.866,12.33,9.344,16.624h-7.077l-6.561-12.5-2.305,3.049v9.451h-6Z",
                transform: "translate(-52.199 -3.588)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_13",
                "data-name": "Path 13",
                d:
                  "M171.787,45.172a33.442,33.442,0,0,1-3.722-8.982c-.031-.127-.062-.256-.092-.385A16.013,16.013,0,0,0,165,40.4a6.19,6.19,0,0,0-.353,4.318q.227.518.482,1.018c1.081,1.706,3.279,2.711,5.976,2.815a14.355,14.355,0,0,1,.683-3.376h0",
                transform: "translate(-87.232 -18.998)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_14",
                "data-name": "Path 14",
                d:
                  "M166.082,20.847a23.153,23.153,0,0,1,.108-10.988,19.917,19.917,0,0,0-4.86,13.06,20.141,20.141,0,0,0,.326,3.59,18.187,18.187,0,0,1,4.425-5.662",
                transform: "translate(-85.601 -5.231)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_15",
                "data-name": "Path 15",
                d:
                  "M202.191,30.486c.017-.061.035-.121.051-.183.085-.32.16-.649.226-.986q.024-.123.046-.249c.061-.334.115-.674.157-1.022.01-.082.017-.166.025-.249.031-.278.054-.561.074-.847.008-.126.018-.251.025-.378.015-.309.023-.622.026-.938,0-.179,0-.359,0-.54,0-.205-.01-.412-.017-.618-.012-.31-.028-.621-.051-.935-.008-.118-.018-.237-.028-.356q-.059-.687-.151-1.378c0-.031-.007-.061-.012-.092-.112-.809-.259-1.619-.438-2.423l0-.011a41.363,41.363,0,0,1-4.153,3.089,49.939,49.939,0,0,1-14.187,6.39c-.012-.028-.022-.055-.034-.084a39.391,39.391,0,0,0,16.434-8.994c.516-.476,1.081-1.008,1.648-1.586q-.243-.9-.543-1.776c-.021-.06-.04-.121-.061-.18-.085-.245-.176-.486-.267-.726q-.084-.22-.173-.436c-.082-.2-.163-.407-.25-.606q-.213-.489-.444-.959c-.061-.123-.124-.242-.186-.362-.127-.246-.256-.487-.39-.723-.061-.107-.121-.214-.184-.319-.2-.33-.4-.652-.605-.96a25.71,25.71,0,0,1-4.438,6.733l2,2.7-7.249,0s.046-.063.118-.179c.469-.739,1.709-3.031,1.5-7.549l2,2.768a10.939,10.939,0,0,0,.861-4.444A11.159,11.159,0,0,0,191.888,5.4c-.05-.079-.1-.161-.147-.238-.084-.128-.173-.249-.259-.374s-.192-.276-.294-.41c-.19-.247-.389-.487-.6-.716.061.064.119.131.18.2-.061-.076-.119-.15-.18-.226a11.223,11.223,0,0,0-14.777-1.555c-1.883,3.509-1.9,8.2-1.162,12.088a52.878,52.878,0,0,1,8-4.945c.59-.307,1.382-.226,1.965-.529a46.338,46.338,0,0,0-9.768,6.429c.035.151.069.3.106.449a39.328,39.328,0,0,0,3.016,8.171,25.907,25.907,0,0,1,4.1-6.014l-2-2.706,7.247.005s-.046.063-.121.179c-.469.737-1.706,3.029-1.5,7.549l-2-2.769a11.019,11.019,0,0,0-.861,4.444,11.15,11.15,0,0,0,1.637,5.678c.048.078.093.16.146.237.082.129.172.25.261.376s.189.274.291.409c.192.245.388.486.6.717-.063-.064-.12-.134-.18-.2.061.075.117.149.18.226a11.223,11.223,0,0,0,14.373,1.83c.413-.309.81-.638,1.2-.978q.292-.613.534-1.264c.116-.314.217-.643.312-.979",
                transform: "translate(-92.428 -0.001)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_16",
                "data-name": "Path 16",
                d:
                  "M174.038,63.608a19.267,19.267,0,0,1-4.059-.219,20.108,20.108,0,0,0,4.762,4.522,10.532,10.532,0,0,1-.7-4.3",
                transform: "translate(-90.189 -33.634)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_23",
                "data-name": "Rectangle 23",
                width: "1.079",
                height: "6.935",
                transform: "translate(0.052 39.667)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_17",
                "data-name": "Path 17",
                d:
                  "M6.039,91.439V84.5h1l1.139,2.963c.232.628.574,1.523.786,2.192h.02c-.05-.885-.111-1.955-.111-3.2V84.5h.948v6.935h-1l-1.15-2.954a23.2,23.2,0,0,1-.736-2.2H6.916c.04.8.081,1.78.081,3.22v1.935Z",
                transform: "translate(-3.204 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_18",
                "data-name": "Path 18",
                d: "M17.936,85.512h-1.22V84.5h3.529v1.008h-1.23v5.927H17.936Z",
                transform: "translate(-8.87 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_19",
                "data-name": "Path 19",
                d:
                  "M29.548,88.28H27.934v2.2h1.836v.957H26.855V84.5h2.793v.957H27.934v1.9h1.614Z",
                transform: "translate(-14.249 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_20",
                "data-name": "Path 20",
                d:
                  "M36.007,84.571a5.668,5.668,0,0,1,1.3-.134,2.37,2.37,0,0,1,1.685.505,1.859,1.859,0,0,1,.514,1.409A1.83,1.83,0,0,1,38.437,88.1v.02c.474.175.726.638.857,1.461a11.208,11.208,0,0,0,.423,1.822H38.6a9.628,9.628,0,0,1-.353-1.688c-.13-.9-.383-1.194-.917-1.194h-.252V91.4H36.007Zm1.069,3.108h.283a1.087,1.087,0,0,0,1.048-1.225c0-.679-.262-1.142-.968-1.152a1.292,1.292,0,0,0-.363.041Z",
                transform: "translate(-19.105 -44.802)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_21",
                "data-name": "Path 21",
                d:
                  "M46.6,91.439V84.5h1l1.139,2.963c.232.628.575,1.523.787,2.192h.02c-.051-.885-.111-1.955-.111-3.2V84.5h.948v6.935h-1l-1.15-2.954c-.242-.637-.545-1.471-.737-2.2h-.02c.04.8.081,1.78.081,3.22v1.935Z",
                transform: "translate(-24.724 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_22",
                "data-name": "Path 22",
                d:
                  "M58.707,89.628l-.363,1.811H57.295L58.807,84.5h1.26l1.4,6.936H60.421l-.363-1.811Zm1.24-.854-.283-1.6c-.08-.453-.17-1.091-.252-1.574h-.03c-.07.483-.171,1.142-.252,1.574l-.3,1.6Z",
                transform: "translate(-30.4 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_23",
                "data-name": "Path 23",
                d: "M68.032,85.512H66.811V84.5h3.53v1.008h-1.23v5.927H68.032Z",
                transform: "translate(-35.449 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_24",
                "data-name": "Rectangle 24",
                width: "1.079",
                height: "6.935",
                transform: "translate(36.121 39.667)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_24",
                "data-name": "Path 24",
                d:
                  "M86.339,87.786c0,2.614-.9,3.643-2.1,3.643-1.351,0-2.027-1.471-2.027-3.56s.767-3.54,2.128-3.54c1.432,0,2,1.595,2,3.458m-3,.092c0,1.513.283,2.593.968,2.593s.918-1.244.918-2.633c0-1.255-.2-2.552-.928-2.552-.675,0-.958,1.143-.958,2.593",
                transform: "translate(-43.622 -44.744)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_25",
                "data-name": "Path 25",
                d:
                  "M93.943,91.439V84.5h1l1.139,2.963c.231.628.574,1.523.787,2.192h.02c-.051-.885-.111-1.955-.111-3.2V84.5h.948v6.935h-1l-1.149-2.954a23.19,23.19,0,0,1-.737-2.2h-.02c.04.8.081,1.78.081,3.22v1.935Z",
                transform: "translate(-49.845 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_26",
                "data-name": "Path 26",
                d:
                  "M106.053,89.628l-.363,1.811h-1.049l1.512-6.936h1.26l1.4,6.936h-1.049l-.363-1.811Zm1.241-.854-.283-1.6c-.081-.453-.171-1.091-.252-1.574h-.03c-.071.483-.171,1.142-.252,1.574l-.3,1.6Z",
                transform: "translate(-55.522 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_27",
                "data-name": "Path 27",
                d: "M116.307,84.5h1.079v6.009H119.2v.926h-2.893Z",
                transform: "translate(-61.711 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_28",
                "data-name": "Path 28",
                d:
                  "M132.441,91.255a2.378,2.378,0,0,1-1,.174c-1.553,0-2.439-1.3-2.439-3.436,0-2.665,1.381-3.622,2.592-3.622a1.879,1.879,0,0,1,.887.175l-.2.927a1.381,1.381,0,0,0-.645-.134c-.736,0-1.492.69-1.492,2.584,0,1.862.685,2.53,1.5,2.53a1.75,1.75,0,0,0,.666-.123Z",
                transform: "translate(-68.448 -44.767)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_29",
                "data-name": "Path 29",
                d:
                  "M142.17,87.786c0,2.614-.9,3.643-2.1,3.643-1.35,0-2.026-1.471-2.026-3.56s.767-3.54,2.127-3.54c1.433,0,2,1.595,2,3.458m-3.006.092c0,1.513.284,2.593.969,2.593s.918-1.244.918-2.633c0-1.255-.2-2.552-.928-2.552-.676,0-.959,1.143-.959,2.593",
                transform: "translate(-73.246 -44.744)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_30",
                "data-name": "Path 30",
                d:
                  "M153.232,87.786c0,2.614-.9,3.643-2.1,3.643-1.351,0-2.027-1.471-2.027-3.56s.767-3.54,2.128-3.54c1.432,0,2,1.595,2,3.458m-3.005.092c0,1.513.283,2.593.969,2.593s.917-1.244.917-2.633c0-1.255-.2-2.552-.928-2.552-.676,0-.959,1.143-.959,2.593",
                transform: "translate(-79.115 -44.744)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_31",
                "data-name": "Path 31",
                d:
                  "M160.839,84.57a5.681,5.681,0,0,1,1.271-.134,2.326,2.326,0,0,1,1.694.525,2.028,2.028,0,0,1,.544,1.482,2.28,2.28,0,0,1-.5,1.554,2.159,2.159,0,0,1-1.633.71h-.3v2.7h-1.069Zm1.069,3.231a1.039,1.039,0,0,0,.262.021c.706,0,1.1-.494,1.1-1.3,0-.648-.263-1.194-.988-1.194a1.252,1.252,0,0,0-.373.052Z",
                transform: "translate(-85.34 -44.801)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_32",
                "data-name": "Path 32",
                d:
                  "M173.865,88.28h-1.614v2.2h1.835v.957h-2.914V84.5h2.793v.957h-1.714v1.9h1.614Z",
                transform: "translate(-90.822 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_33",
                "data-name": "Path 33",
                d:
                  "M180.324,84.571a5.665,5.665,0,0,1,1.3-.134,2.367,2.367,0,0,1,1.684.505,1.856,1.856,0,0,1,.514,1.409,1.83,1.83,0,0,1-1.069,1.749v.02c.474.175.726.638.858,1.461a11.159,11.159,0,0,0,.423,1.822h-1.12a9.586,9.586,0,0,1-.353-1.688c-.13-.9-.383-1.194-.918-1.194h-.252V91.4h-1.068Zm1.068,3.108h.283a1.087,1.087,0,0,0,1.049-1.225c0-.679-.263-1.142-.969-1.152a1.293,1.293,0,0,0-.362.041Z",
                transform: "translate(-95.678 -44.802)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_34",
                "data-name": "Path 34",
                d:
                  "M191.444,89.628l-.363,1.811h-1.049l1.512-6.936h1.261l1.4,6.936h-1.048l-.363-1.811Zm1.241-.854-.283-1.6c-.081-.453-.171-1.091-.252-1.574h-.031c-.07.483-.171,1.142-.252,1.574l-.3,1.6Z",
                transform: "translate(-100.83 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_35",
                "data-name": "Path 35",
                d: "M200.769,85.512h-1.22V84.5h3.53v1.008h-1.231v5.927h-1.078Z",
                transform: "translate(-105.879 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_25",
                "data-name": "Rectangle 25",
                width: "1.078",
                height: "6.935",
                transform: "translate(98.43 39.667)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_36",
                "data-name": "Path 36",
                d:
                  "M219.075,87.786c0,2.614-.9,3.643-2.1,3.643-1.351,0-2.027-1.471-2.027-3.56s.766-3.54,2.127-3.54c1.433,0,2,1.595,2,3.458m-3.005.092c0,1.513.283,2.593.968,2.593s.918-1.244.918-2.633c0-1.255-.2-2.552-.928-2.552-.675,0-.958,1.143-.958,2.593",
                transform: "translate(-114.051 -44.744)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_37",
                "data-name": "Path 37",
                d:
                  "M226.681,91.439V84.5h1l1.14,2.963c.231.628.575,1.523.787,2.192h.021c-.052-.885-.112-1.955-.112-3.2V84.5h.948v6.935h-1l-1.15-2.954c-.242-.637-.545-1.471-.737-2.2h-.02c.04.8.081,1.78.081,3.22v1.935Z",
                transform: "translate(-120.275 -44.837)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_38",
                "data-name": "Path 38",
                d:
                  "M237.817,90.2a2.284,2.284,0,0,0,1.049.277.927.927,0,0,0,1.029-.968c0-.555-.333-.874-.887-1.266a2.455,2.455,0,0,1-1.281-1.934,1.853,1.853,0,0,1,1.987-1.935,2.149,2.149,0,0,1,1.019.247l-.2.947a1.507,1.507,0,0,0-.837-.237.835.835,0,0,0-.907.844c0,.536.273.783.968,1.235a2.328,2.328,0,0,1,1.2,1.986,1.954,1.954,0,0,1-2.088,2.037,2.522,2.522,0,0,1-1.23-.287Z",
                transform: "translate(-126.088 -44.767)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_39",
                "data-name": "Path 39",
                d:
                  "M227.958,10.018c.257.315.5.643.739.977.076.107.15.216.224.325q.241.353.469.717c.078.126.157.252.233.379.154.258.3.52.445.785.058.107.119.21.175.318.179.342.348.691.51,1.043.083.179.16.361.239.543s.167.389.246.586.164.412.242.619c.064.172.125.345.185.519.077.218.155.436.227.656,1.965-2.429,3.407-5.34,2.193-8.2,0,0,.008,0,.012,0a20.1,20.1,0,0,0-6.362-6.96,11.544,11.544,0,0,1,.223,7.691",
                transform: "translate(-120.834 -1.235)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_40",
                "data-name": "Path 40",
                d:
                  "M241.079,26.857a16.223,16.223,0,0,1-3.615,5.039c.129.487.243.976.346,1.467.015.07.027.141.041.212q.126.628.224,1.256c.017.11.031.22.047.329.054.379.1.756.139,1.133.013.135.025.268.037.4q.045.525.069,1.044c.007.15.014.3.019.451.009.328.012.653.009.977,0,.159,0,.318-.006.476-.008.315-.026.624-.046.933-.011.157-.018.316-.031.472-.028.32-.067.632-.108.944-.018.135-.03.273-.05.406-.067.439-.145.87-.238,1.29a20.011,20.011,0,0,0,3.164-16.831",
                transform: "translate(-125.996 -14.25)"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }