var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "220",
        height: "120",
        viewBox: "0 0 220 120"
      }
    },
    [
      _c("g", { attrs: { id: "medicom", transform: "translate(44 32.999)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_26",
            "data-name": "Rectangle 26",
            width: "220",
            height: "120",
            transform: "translate(-44 -32.999)",
            fill: "none"
          }
        }),
        _vm._v(" "),
        _c(
          "g",
          { attrs: { id: "logo", transform: "translate(-10.791 7.483)" } },
          [
            _c("path", {
              attrs: {
                id: "Path_23",
                "data-name": "Path 23",
                d:
                  "M21.645,63.315,13.137,80.647,4.548,63.315H3.32L0,86.5H3.309L5.286,72.008l7.2,14.493h1.286l7.109-14.372L22.865,86.5h3.34L22.867,63.315Z",
                transform: "translate(0 -62.773)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_24",
                "data-name": "Path 24",
                d:
                  "M61.031,86.5H75.264V83.179H64.431V75.693H75.264V72.371H64.431V66.637H75.354V63.315H61.031Z",
                transform: "translate(-31.238 -62.773)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_25",
                "data-name": "Path 25",
                d:
                  "M101.682,66.638h.979a25.8,25.8,0,0,1,6.668.6,7.252,7.252,0,0,1,4,2.867,8.866,8.866,0,0,1,1.445,5.114,8.4,8.4,0,0,1-1.326,4.809,6.706,6.706,0,0,1-3.617,2.682,23.585,23.585,0,0,1-6.179.54h-1.971Zm9.273-2.5a23.749,23.749,0,0,0-7.393-.826H98.4V86.5h7.615a14.7,14.7,0,0,0,6.694-1.264,9.8,9.8,0,0,0,3.964-3.939,12.179,12.179,0,0,0,1.511-6.113,12.418,12.418,0,0,0-1.906-6.92,10.231,10.231,0,0,0-5.32-4.122",
                transform: "translate(-50.364 -62.774)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_26",
                "data-name": "Path 26",
                d:
                  "M179.157,79.812a9.23,9.23,0,0,1-7.334,3.417A9.123,9.123,0,0,1,165.23,80.7a8.341,8.341,0,0,1-2.6-6.3,8.8,8.8,0,0,1,1.162-4.5,8.563,8.563,0,0,1,3.277-3.179,9.343,9.343,0,0,1,4.618-1.189,9.649,9.649,0,0,1,4.193.909,8.972,8.972,0,0,1,3.255,2.662l.354.459,2.659-2.028-.334-.461a11.485,11.485,0,0,0-4.307-3.6,13.23,13.23,0,0,0-5.851-1.277,12.394,12.394,0,0,0-8.939,3.461,11.473,11.473,0,0,0-3.585,8.547,11.926,11.926,0,0,0,12.554,12.262,13.647,13.647,0,0,0,5.771-1.2,11.756,11.756,0,0,0,4.343-3.4l.369-.468-2.657-2Z",
                transform: "translate(-81.451 -62.205)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_27",
                "data-name": "Path 27",
                d:
                  "M230.135,74.325a9.01,9.01,0,0,1-1.145,4.5A8.349,8.349,0,0,1,225.809,82a8.877,8.877,0,0,1-4.452,1.166,8.671,8.671,0,0,1-8.687-8.741,8.976,8.976,0,0,1,1.154-4.516,8.8,8.8,0,0,1,7.534-4.4,8.766,8.766,0,0,1,4.412,1.2,8.56,8.56,0,0,1,3.222,3.175,8.765,8.765,0,0,1,1.143,4.445m-8.875-12.12a11.914,11.914,0,0,0-10.42,6.068,12.357,12.357,0,0,0-1.609,6.164,11.629,11.629,0,0,0,3.538,8.517,11.874,11.874,0,0,0,8.671,3.52,12.181,12.181,0,0,0,8.6-20.766,12.026,12.026,0,0,0-8.774-3.5",
                transform: "translate(-107.093 -62.205)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_28",
                "data-name": "Path 28",
                d:
                  "M284.023,63.315l-8.507,17.332-8.59-17.332H265.7L262.378,86.5h3.308l1.977-14.492,7.2,14.492h1.286l7.108-14.372L285.245,86.5h3.34l-3.34-23.186Z",
                transform: "translate(-134.296 -62.773)"
              }
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "Rectangle_23",
                "data-name": "Rectangle 23",
                width: "3.39",
                height: "23.185",
                transform: "translate(71.021 0.542)"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_30",
                "data-name": "Path 30",
                d:
                  "M153.669,127.669a5.6,5.6,0,0,1,.859.2v-1.2a4.5,4.5,0,0,0-.79-.188,6.188,6.188,0,0,0-.929-.066,4.459,4.459,0,0,0-1.715.323,3.507,3.507,0,0,0-1.264.871,3.8,3.8,0,0,0-.773,1.309,4.873,4.873,0,0,0-.268,1.639,4.688,4.688,0,0,0,.251,1.572,3.278,3.278,0,0,0,.749,1.223,3.361,3.361,0,0,0,1.243.79,4.894,4.894,0,0,0,1.725.28,6.864,6.864,0,0,0,.969-.066,6.657,6.657,0,0,0,.871-.182v-1.217a5.172,5.172,0,0,1-.854.2,5.91,5.91,0,0,1-.893.069,3.187,3.187,0,0,1-1.093-.176,2.142,2.142,0,0,1-.816-.522,2.308,2.308,0,0,1-.513-.863,3.628,3.628,0,0,1-.179-1.2,3.506,3.506,0,0,1,.2-1.22,2.519,2.519,0,0,1,.545-.9,2.309,2.309,0,0,1,.837-.554,2.907,2.907,0,0,1,1.064-.19,5.413,5.413,0,0,1,.779.058m-28.342-1.108h-1.413v7.718h1.413Zm-30.488,0H93.357v7.719H94.77v-3.5q0-.78-.069-2.2h.035q.71,1.217,1.373,2.14l2.515,3.553h1.483v-7.719H98.693v3.5q0,.687.069,2.192h-.035q-.519-.917-1.349-2.1Zm-25.29,0H68.136v7.718h1.413Zm-29.726,0v7.719h5.024V133.1H41.236v-6.536ZM15.49,127.669a5.606,5.606,0,0,1,.86.2v-1.2a4.509,4.509,0,0,0-.79-.188,6.192,6.192,0,0,0-.929-.066,4.468,4.468,0,0,0-1.716.323,3.514,3.514,0,0,0-1.263.871,3.811,3.811,0,0,0-.773,1.309,4.861,4.861,0,0,0-.268,1.639,4.713,4.713,0,0,0,.251,1.572,3.292,3.292,0,0,0,.75,1.223,3.369,3.369,0,0,0,1.243.79,4.9,4.9,0,0,0,1.725.28,6.863,6.863,0,0,0,.969-.066,6.64,6.64,0,0,0,.871-.182v-1.217a5.16,5.16,0,0,1-.853.2,5.928,5.928,0,0,1-.894.069,3.186,3.186,0,0,1-1.093-.176,2.138,2.138,0,0,1-.816-.522,2.3,2.3,0,0,1-.514-.863,3.654,3.654,0,0,1-.179-1.2,3.518,3.518,0,0,1,.2-1.22,2.533,2.533,0,0,1,.545-.9,2.317,2.317,0,0,1,.837-.554,2.909,2.909,0,0,1,1.064-.19,5.407,5.407,0,0,1,.779.058",
                transform: "translate(-5.431 -95.071)"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }