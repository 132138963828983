var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "block sluzba-2-intro" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "item-right" }, [
        _c(
          "video",
          {
            attrs: { playsinline: "", autoplay: "", muted: "", loop: "" },
            domProps: { muted: true }
          },
          [
            _c("source", {
              attrs: {
                src: "/theme/images/sluzby/automatizace.mp4",
                type: "video/mp4"
              }
            })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "block block_sluzby" }, [
      _c(
        "div",
        { staticClass: "block-container" },
        [
          _c("HeadingWithLine", { attrs: { theme: "dark" } }, [
            _vm._v("\n                Naše služby\n            ")
          ]),
          _vm._v(" "),
          _vm._m(2)
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "block block_reference" }, [
      _c(
        "div",
        { staticClass: "block-container" },
        [
          _c("HeadingWithLine", { attrs: { theme: "light" } }, [
            _vm._v("\n                Recenze\n            ")
          ]),
          _vm._v(" "),
          _vm._m(3)
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "block reference_kare" }, [
      _c("div", { staticClass: "item-left" }, [
        _c("div", { staticClass: "block-container" }, [
          _c("div", { staticClass: "svg-logo" }, [
            _c(
              "svg",
              {
                staticStyle: { height: "20px" },
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 169.06 30.2"
                }
              },
              [
                _c(
                  "g",
                  { attrs: { id: "Vrstva_2", "data-name": "Vrstva 2" } },
                  [
                    _c("g", { attrs: { id: "KARE" } }, [
                      _c("polygon", {
                        staticClass: "cls-1",
                        attrs: {
                          points:
                            "0 0.01 9.27 0.01 9.27 11.1 12.61 11.1 24.3 0.01 36.52 0.01 20.54 14.77 35.66 30.2 23.21 30.2 12.36 18.61 9.27 18.61 9.27 30.2 0 30.2 0 0.01"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "cls-1",
                        attrs: {
                          d:
                            "M56.17,18.34H68L62,7ZM56,.05H68l16.56,30.1H74.31l-2.87-5.32H52.71L50,30.15H39.68Z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "cls-1",
                        attrs: {
                          d:
                            "M100.11,14.85h14.32c2.5,0,3.42-1,3.42-3.17v-1c0-2.67-1.34-3.17-4.26-3.17H100.11ZM90.84,0h27c7.14,0,9.06,3.54,9.06,9v1.91c0,4-.92,6.43-5.18,7.43v.08c2.76.5,5,1.71,5,6.67v5.13h-9.27V26.56c0-3.17-.91-4.21-3.79-4.21H100.11v7.84H90.84Z"
                        }
                      }),
                      _vm._v(" "),
                      _c("polygon", {
                        staticClass: "cls-1",
                        attrs: {
                          points:
                            "136.44 0 169.06 0 169.06 7 145.71 7 145.71 12.12 167.25 12.12 167.25 17.82 145.71 17.82 145.71 22.93 169.05 22.93 169.05 30.19 136.44 30.19 136.44 0"
                        }
                      })
                    ])
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "reference_h2" }, [
            _vm._v("Eshop s designovým nábytkem")
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat explicabo ipsum consequuntur saepe, pariatur corporis."
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(4)
    ]),
    _vm._v(" "),
    _vm._m(5),
    _vm._v(" "),
    _vm._m(6),
    _vm._v(" "),
    _vm._m(7)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-left" }, [
      _c("div", { staticClass: "block-container heading-block" }, [
        _c("h1", [
          _vm._v("Cokoliv, co nemusíte "),
          _c("br"),
          _vm._v(" dělat, tak nedělejte"),
          _c("span", { staticClass: "red" }, [_vm._v(".")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block block_1" }, [
      _c("div", { staticClass: "item-left" }, [
        _c("div", { staticClass: "background" }),
        _vm._v(" "),
        _c("img", {
          attrs: { src: "/theme/images/sluzby/image__20_.webp", alt: "" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-right" }, [
        _c("div", { staticClass: "block-container" }, [
          _c("div", { staticClass: "block-text-content" }, [
            _c("h2", [
              _vm._v(
                "Umíme to rychleji, efektivněji a tedy i levněji než váš ERP systém..."
              )
            ]),
            _vm._v(" "),
            _c("div", {}, [
              _c("p", [
                _vm._v(
                  "Umíme najít opakující se úkoly a procesy. Analyzujeme je a hledáme způsoby, jak je zpracovat strojově a automatizovat."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Zlepšujeme pracovní postupy a usnadňujeme práci spojením aplikací, o kterých třeba ani netušíte, že to jde. Proč? Protože to je ve svém důsledku vždy levnější..."
                )
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("Nadstavba nad ERP")]),
      _vm._v(" "),
      _c("li", [_vm._v("Automatizace firemních procesů")]),
      _vm._v(" "),
      _c("li", [_vm._v("Automatizace e‑shopů")]),
      _vm._v(" "),
      _c("li", [_vm._v("Webová analytika")]),
      _vm._v(" "),
      _c("li", [_vm._v("Chytrý reporting")]),
      _vm._v(" "),
      _c("li", [_vm._v("Vývoj interních/firemních systémů")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-content-container" }, [
      _c("div", { staticClass: "reference-slide" }, [
        _c("p", { staticClass: "citat" }, [
          _vm._v('"Spolupráce s firmou FOREGROUND nám ušetřila sta tisíce..."')
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "osoba-card" }, [
          _c("img", { attrs: { src: "", alt: "" } }),
          _vm._v(" "),
          _c("div", [
            _c("p", { staticClass: "jmeno" }, [_vm._v("Ondřej Bělský")]),
            _vm._v(" "),
            _c("p", { staticClass: "text" }, [_vm._v("funkce: CEO")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "reference-footer" }, [
        _c("ul", { staticClass: "reference-tagy" }, [
          _c("li", [_vm._v("Nadstavba nad ERP")]),
          _vm._v(" "),
          _c("li", [_vm._v("Automatizace e-shopu")]),
          _vm._v(" "),
          _c("li", [_vm._v("Webová analytika")]),
          _vm._v(" "),
          _c("li", [_vm._v("Chytrý reporting")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "reference-paginator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-right" }, [
      _c("div", { staticClass: "background" }),
      _vm._v(" "),
      _c("img", { attrs: { src: "/theme/images/sluzby/kare.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block reference_atex" }, [
      _c("div", { staticClass: "item-left" }, [
        _c("img", {
          attrs: { src: "/theme/images/sluzby/img-main-atex-shop.jpg", alt: "" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-right" }, [
        _c("div", { staticClass: "block-container" }, [
          _c("div", { staticClass: "logo" }, [
            _c("img", {
              staticStyle: { filter: "invert(1)" },
              attrs: {
                src: "/theme/images/atex/logo.png",
                alt: "",
                height: "30"
              }
            })
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "reference_h2" }, [_vm._v("Heading")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis animi, in et similique unde autem hic facere soluta quibusdam, minus ratione incidunt dolor ducimus asperiores molestias maiores quis, saepe dolores."
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block reference_sc" }, [
      _c("div", { staticClass: "item-left" }, [
        _c(
          "div",
          { staticClass: "block-container", staticStyle: { height: "100%" } },
          [
            _c("div", { staticClass: "block-text-content" }, [
              _c("h2", [_vm._v("SC Brno")]),
              _vm._v(" "),
              _c("div", { staticClass: "text-self-end" }, [
                _c("p", [
                  _vm._v(
                    "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero quis veritatis minima maxime quae! Ea accusantium dolores odio veritatis fugit quisquam consequuntur itaque rem, animi exercitationem mollitia excepturi maxime maiores!"
                  )
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sluzby-2-reference" }, [
      _c(
        "div",
        {
          staticClass:
            "sluzby-reference-item reference-item-padding-lr reference-item-padding-tb",
          staticStyle: {
            "background-image": "url('/theme/images/sluzby/microwell_02.jpg')",
            "background-size": "cover",
            "aspect-ratio": "1344 / 804"
          }
        },
        [
          _c("div", { staticClass: "reference-item-layout" }, [
            _c("h2", [_vm._v("Microwell")]),
            _vm._v(" "),
            _c("div", { staticClass: "reference-img" })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }