<template>
    <div class="carusel-container" ref="caruselContainer">
        <div class="carousel-sticky-container">
            <div class="carousel-heading">
                <slot name="heading" />
            </div>
            <div class="carusel-wrapper">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
    import { animate, scroll } from "motion";
    export default {
        mounted() {
            const items = document.querySelectorAll(".carusel-item");
            // Animate gallery horizontally during vertical scroll
            scroll(
                animate(".carusel-wrapper", {
                    transform: ["none", `translateX(-${(items.length - 1)/5}00vw)`]
                }),
                { target: document.querySelector(".carusel-container") }
            );
        },
    }
</script>

<style lang="scss" scoped>
    .carousel-heading{

    }
    .carousel-sticky-container{
        position: sticky;
        top: 0px;
    }
    .carusel-container {
        // overflow-x: scroll;
        position: relative;
        min-height: 800px;
        margin-bottom: -20px;
        height: 250vh;
        z-index: 3;
        max-width: 100vw;
    }
    .carusel-wrapper {
        // position: absolute;
        // top: 0px;
        // left: 0px;
        // position: sticky;
        // top: 0px;
        padding-left: 200px;
        padding-right: 200px;
        display: flex;
        align-items: center;
        gap: 90px;
        // height: 100vh;
        height: calc(100vh - 120px);
    }
    .carusel-item {
        width: 25vw;
        flex: 0 0 auto;
    }
    .carusel-item img {
        width: 100%;
    }
    .carusel-item-text-wrapper {
        margin-left: 30px;
        margin-top: 30px;
    }
    .carusel-item-text-wrapper h3 {
        font-size: 45px;
        line-height: 1;
        font-weight: 600;
    }
    .typ {
        font-size: 25px;
        color: rgba(25, 27, 29, 0.6);
        line-height: 1;
        font-weight: 600;
    }
</style>