var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "footer-container",
        class: { "dark-theme": this.theme == "dark" }
      },
      [
        _c("div", { staticClass: "footer-block-1" }, [
          _c("div", { staticClass: "socialni-site" }, [
            _c("a", { attrs: { href: "#" } }, [
              _c(
                "svg",
                {
                  attrs: {
                    id: "ig",
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "18.537",
                    height: "18.537",
                    viewBox: "0 0 18.537 18.537"
                  }
                },
                [
                  _c(
                    "g",
                    { attrs: { id: "instagram", transform: "translate(1 1)" } },
                    [
                      _c("path", {
                        attrs: {
                          id: "Path_25",
                          "data-name": "Path 25",
                          d:
                            "M7.134,3H15.4a4.134,4.134,0,0,1,4.134,4.134V15.4A4.134,4.134,0,0,1,15.4,19.537H7.134A4.134,4.134,0,0,1,3,15.4V7.134A4.134,4.134,0,0,1,7.134,3Z",
                          transform: "translate(-3 -3)",
                          fill: "none",
                          stroke: "#000",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Path_26",
                          "data-name": "Path 26",
                          d:
                            "M18.644,14.757a3.307,3.307,0,1,1-2.786-2.786,3.307,3.307,0,0,1,2.786,2.786Z",
                          transform: "translate(-7.068 -7.01)",
                          fill: "none",
                          stroke: "#000",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Path_27",
                          "data-name": "Path 27",
                          d: "M26.25,9.75h0",
                          transform: "translate(-13.434 -6.029)",
                          fill: "none",
                          stroke: "#000",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2"
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("a", { attrs: { href: "#" } }, [
              _c(
                "svg",
                {
                  attrs: {
                    id: "linkedin",
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "17.971",
                    height: "17.971",
                    viewBox: "0 0 17.971 17.971"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Icon_awesome-linkedin",
                      "data-name": "Icon awesome-linkedin",
                      d:
                        "M16.687,0H1.28A1.289,1.289,0,0,0,0,1.3V16.675a1.289,1.289,0,0,0,1.28,1.3H16.687a1.293,1.293,0,0,0,1.284-1.3V1.3A1.293,1.293,0,0,0,16.687,0ZM5.431,15.4H2.768V6.827H5.435V15.4ZM4.1,5.656A1.544,1.544,0,1,1,5.644,4.112,1.545,1.545,0,0,1,4.1,5.656ZM15.415,15.4H12.752V11.232c0-.995-.02-2.274-1.384-2.274-1.388,0-1.6,1.083-1.6,2.2V15.4H7.1V6.827H9.659V8H9.7a2.805,2.805,0,0,1,2.523-1.384c2.7,0,3.2,1.777,3.2,4.088Z",
                      fill: "#000"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("a", { attrs: { href: "#" } }, [
              _c(
                "svg",
                {
                  attrs: {
                    id: "facebook",
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "8.013",
                    height: "14.961",
                    viewBox: "0 0 8.013 14.961"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      id: "facebook-f",
                      d:
                        "M9.1,8.416l.416-2.708h-2.6V3.951A1.354,1.354,0,0,1,8.441,2.488H9.623V.183A14.4,14.4,0,0,0,7.526,0,3.306,3.306,0,0,0,3.988,3.644V5.708H1.609V8.416H3.988v6.546H6.915V8.416Z",
                      transform: "translate(-1.609 0)",
                      fill: "#000"
                    }
                  })
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "cta-btn",
              on: {
                click: function($event) {
                  return _vm.zobrazitCTA()
                }
              }
            },
            [
              _vm._v("Let's Talk \n                "),
              _c("span", { staticClass: "arrow_icon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M3.75 12H20.25",
                        stroke: "currentColor",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M13.5 5.25L20.25 12L13.5 18.75",
                        stroke: "currentColor",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                )
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer-text-big" }, [
      _vm._v("Want to create something "),
      _c("br"),
      _c("span", [_vm._v("awesome?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }